import React from 'react'
import {
  Message,
  MessageContent,
  MessageTimestamp,
  MessageProfile,
  MessageLine,
  MessageText,
  Answer,
  AnswerBox,
  Question, LogoImg, AnswerMessage
} from "./ChatRoom.style"
import Swal from "sweetalert2";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {SERVER_URL} from "../../routes";
import {Box, Grid} from "@mui/material";

function Chatting({
  item,
  theme,
  userId,
  onReplace,
  onAnswer,
  onPublishRemove,
  onAnswerRemove,
  isAuthenticated,
  fileStorage
}) {
  const _onClickAnswer = () => {
    Swal.fire({
      title: item.username + (item.company ? ` (${item.company})` : ""),
      text: item.message,
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '답변',
      showLoaderOnConfirm: true,
      showDenyButton: true,
      denyButtonText: `삭제`
    }).then((result) => {
      if (result.isConfirmed) {
        onAnswer(result.value, item.id);
      } else if (result.isDenied) {
        onPublishRemove(item.id);
        Swal.fire(
            '삭제',
            item.username + (item.company ? '(' + item.company + ')' : '') + ':' + item.message,
            'success'
        );
      }
    });
  }

  const _onClickRemove = () => {
    Swal.fire({
      title: '답변 삭제',
      text: item.message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dd3333',
      cancelButtonColor: '#6e7881',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        onAnswerRemove(item.id);
        Swal.fire(
            '삭제',
            item.username + (item.company ? '(' + item.company + ')' : '') + ':' + item.message,
            'success'
        );
      }
    })
  }

  return (
      <>
        {
          item.type === "END" ?
              <Answer
                  theme={{
                    fontColor: theme.answerColor,
                    fontSize: theme.messageSize,
                    backgroundColor: theme.answerBgColor
                  }}
                  sx={{textAlign: "center", pt: 4, pb: 4}}
              >
                  행사가 종료되었습니다.
              </Answer>
              :
              item.questionUsername ?
                  <Answer
                    theme={{
                      fontColor: theme.answerColor,
                      fontSize: theme.messageSize,
                      backgroundColor: theme.answerBgColor
                    }}
                    onClick={isAuthenticated ? _onClickRemove : null}>
                    <Question>
                      <div>{item.questionUsername} 에게 답변</div>
                      <div>{item.questionMessage}</div>
                    </Question>
                    <AnswerBox>
                      <SubdirectoryArrowRightIcon fill={theme.answerColor}/>
                      {
                        fileStorage ?
                            <Box sx={{mr: "5px"}}>
                              <LogoImg src={SERVER_URL + fileStorage.fileDownloadUri} alt=""/>
                            </Box>
                            :
                            ""
                      }
                      <Box sx={{mr: 1, mt: "5px"}}>{item.username}</Box>
                    </AnswerBox>
                    <AnswerMessage dangerouslySetInnerHTML={ {__html: onReplace(item.message)} }/>
                  </Answer>
              :
              <Message align={(item.userId === userId) ? "right" : "left"}>
                    <MessageContent
                        onClick={isAuthenticated ? _onClickAnswer : null}>
                      <MessageTimestamp
                          theme={{
                            fontColor: theme.timeColor,
                            fontSize: theme.timeSize
                          }}>
                        {item.time}
                      </MessageTimestamp>
                      <Grid
                          container
                          direction="row"
                          justifyContent={(item.userId === userId) ? "flex-end" : "flex-start"}
                          alignItems="center"
                      >
                        {
                          (item.userId !== userId) ?
                              <>
                                {
                                  fileStorage && (item.role === "ADMIN") ?
                                      <span>
                                        <LogoImg src={SERVER_URL + fileStorage.fileDownloadUri} alt=""/>
                                      </span>
                                      :
                                      ""
                                }
                                <MessageProfile
                                    theme={{
                                      fontColor: (item.role === "ADMIN" ? theme.adminNicknameColor : theme.nicknameColor),
                                      fontSize: theme.nicknameSize
                                    }}>
                                  <span>
                                    {item.username}
                                  </span>
                                </MessageProfile>
                                <MessageLine
                                    theme={{
                                      fontColor: (item.role === "ADMIN" ? theme.adminNicknameColor : theme.nicknameColor),
                                      fontSize: theme.nicknameSize
                                    }}>
                                  :
                                </MessageLine>
                              </>
                              :
                              ""
                        }
                        <MessageText
                            theme={{
                              fontColor: (item.role === "ADMIN" ? theme.adminMessageColor : theme.messageColor),
                              fontSize: theme.messageSize
                            }}
                            dangerouslySetInnerHTML={ {__html: onReplace(item.message)} }>
                        </MessageText>
                      </Grid>
                    </MessageContent>
                  </Message>
        }
      </>
  );
}

export default Chatting;