import React, {useEffect, useState, useRef} from 'react'
import FadeMotion from "../../components/layouts/FadeMotion";
import {
  Box, FormHelperText,
  Paper, Slider,
  TextField,
  Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {
  useGetTeamListAllQuery
} from "../../features/team/teamApiSlice";
import {useCreateThemeMutation, useGetThemeByIdQuery} from "../../features/theme/themeApiSlice";
import {FlexBox, ColLg5} from "./Theme.style";
import {
  Container,
  BusinessLink,
  Wrapper,
  MessageContainer,
  MessageInputContainer,
  MessageWrapper,
  Message,
  MessageContent,
  MessageTimestamp,
  MessageProfile,
  MessageLine,
  MessageText,
  MessageInputWrapper,
  MessageTextArea,
  SendButton,
  NoticeWrapper,
  NoticeBox, Answer, Question, AnswerBox
} from '../chatsquare/ChatRoom.style';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {scrollToRef} from "../../utils/func";
import ReactAvatarEditor from 'react-avatar-editor';
import {SERVER_URL} from "../../routes";
import {ThemeLoadingButton} from "../../components/layouts/Layout.style";
import {THEME} from "../../components/layouts/THEME";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

function ThemeCreate() {
  const refTeamId = useRef(null);
  const refTitle = useRef(null);

  /* Update 시 id 값 존재함 */
  const {id} = useParams();

  /* Team List 를 가져옵니다. */
  const {
    data: teamList,
    isSuccess: teamIsSuccess
  } = useGetTeamListAllQuery();

  /* Team List Option */
  let teamOption;
  if(teamIsSuccess) {
    const { ids, entities } = teamList;
    teamOption = ids.map(id => (
        <MenuItem key={id} value={entities[id].id}>{entities[id].name}</MenuItem>
    ));
  }

  /* Chatting RoomEnter */
  /* Theme Init */
  const [theme, setTheme] = useState({
    teamId: "",
    title: "",
    noticeColor: "ffffff",
    noticeBgColor: "3b44ac",
    noticeSize: 14,
    backgroundColor: "ffffff",
    timeColor: "aaaaaa",
    timeSize: 12,
    messageColor: "6c7982",
    messageSize: 14,
    messageLineColor: "3b44ac",
    footerBgColor: "000000",
    nicknameColor: "6c7982",
    nicknameSize: 14,
    sendBtnBgColor: "3b44ac",
    sendBtnColor: "ffffff",
    adminNicknameColor: "6c7982",
    adminMessageColor: "6c7982",
    businessText: "Neodigm",
    businessLink: "https://www.neodigm.com",
    businessColor: "ffffff",
    answerColor: "ffffff",
    answerBgColor: "3b44ac",
    fileStorage: null,
    fileStorageUpdate: null
  });

  const [teamIdErr, setTeamIdErr] = useState(null);
  const [titleErr, setTitleErr] = useState(null);
  const [noticeColorErr, setNoticeColorErr] = useState(null);
  const [noticeBgColorErr, setNoticeBgColorErr] = useState(null);
  const [backgroundColorErr, setBackgroundColorErr] = useState(null);
  const [timeColorErr, setTimeColorErr] = useState(null);
  const [messageColorErr, setMessageColorErr] = useState(null);
  const [messageLineColorErr, setMessageLineColorErr] = useState(null);
  const [footerBgColorErr, setFooterBgColorErr] = useState(null);
  const [nicknameColorErr, setNicknameColorErr] = useState(null);
  const [sendBtnBgColorErr, setSendBtnBgColorErr] = useState(null);
  const [sendBtnColorErr, setSendBtnColorErr] = useState(null);
  const [adminNicknameColorErr, setAdminNicknameColorErr] = useState(null);
  const [adminMessageColorErr, setAdminMessageColorErr] = useState(null);
  const [businessTextErr, setBusinessTextErr] = useState(null);
  const [businessLinkErr, setBusinessLinkErr] = useState(null);
  const [businessColorErr, setBusinessColorErr] = useState(null);
  const [answerColorErr, setAnswerColorErr] = useState(null);
  const [answerBgColorErr, setAnswerBgColorErr] = useState(null);

  const navigate = useNavigate();

  const [create, {isLoading}] = useCreateThemeMutation();

  const {
    data: themeData,
    isSuccess
  } = useGetThemeByIdQuery(id, {skip: (id === undefined)});

  useEffect(() => {
    if (isSuccess) {
      setTheme({
        teamId: themeData.team.id,
        title: themeData.title,
        noticeColor: themeData.noticeColor,
        noticeBgColor: themeData.noticeBgColor,
        noticeSize: themeData.noticeSize,
        backgroundColor: themeData.backgroundColor,
        timeColor: themeData.timeColor,
        timeSize: themeData.timeSize,
        messageColor: themeData.messageColor,
        messageSize: themeData.messageSize,
        messageLineColor: themeData.messageLineColor,
        footerBgColor: themeData.footerBgColor,
        nicknameColor: themeData.nicknameColor,
        nicknameSize: themeData.nicknameSize,
        sendBtnBgColor: themeData.sendBtnBgColor,
        sendBtnColor: themeData.sendBtnColor,
        adminNicknameColor: themeData.adminNicknameColor,
        adminMessageColor: themeData.adminMessageColor,
        businessText: themeData.businessText,
        businessLink: themeData.businessLink,
        businessColor: themeData.businessColor,
        answerColor: themeData.answerColor,
        answerBgColor: themeData.answerBgColor,
        fileStorageUpdate: themeData.fileStorage
      });
    }
  }, [isSuccess, themeData]);

  const onChangeTheme = (e) => {
    switch (e.target.name) {
      case "teamId":
        setTeamIdErr(false);
        setTheme({
          ...theme,
          teamId: e.target.value
        });
        break;
      case "title":
        setTitleErr(false);
        setTheme({
          ...theme,
          title: e.target.value
        });
        break;
      case "noticeColor":
        setNoticeColorErr(false);
        setTheme({
          ...theme,
          noticeColor: e.target.value
        });
        break;
      case "noticeBgColor":
        setNoticeBgColorErr(false);
        setTheme({
          ...theme,
          noticeBgColor: e.target.value
        });
        break;
      case "noticeSize":
        setTheme({
          ...theme,
          noticeSize: e.target.value
        });
        break;
      case "backgroundColor":
        setBackgroundColorErr(false);
        setTheme({
          ...theme,
          backgroundColor: e.target.value
        });
        break;
      case "timeColor":
        setTimeColorErr(false);
        setTheme({
          ...theme,
          timeColor: e.target.value
        });
        break;
      case "timeSize":
        setTheme({
          ...theme,
          timeSize: e.target.value
        });
        break;
      case "messageColor":
        setMessageColorErr(false);
        setTheme({
          ...theme,
          messageColor: e.target.value
        });
        break;
      case "messageSize":
        setTheme({
          ...theme,
          messageSize: e.target.value
        });
        break;
      case "messageLineColor":
        setMessageLineColorErr(false);
        setTheme({
          ...theme,
          messageLineColor: e.target.value
        });
        break;
      case "footerBgColor":
        setFooterBgColorErr(false);
        setTheme({
          ...theme,
          footerBgColor: e.target.value
        });
        break;
      case "nicknameColor":
        setNicknameColorErr(false);
        setTheme({
          ...theme,
          nicknameColor: e.target.value
        });
        break;
      case "nicknameSize":
        setTheme({
          ...theme,
          nicknameSize: e.target.value
        });
        break;
      case "sendBtnBgColor":
        setSendBtnBgColorErr(false);
        setTheme({
          ...theme,
          sendBtnBgColor: e.target.value
        });
        break;
      case "sendBtnColor":
        setSendBtnColorErr(false);
        setTheme({
          ...theme,
          sendBtnColor: e.target.value
        });
        break;
      case "adminNicknameColor":
        setAdminNicknameColorErr(false);
        setTheme({
          ...theme,
          adminNicknameColor: e.target.value
        });
        break;
      case "adminMessageColor":
        setAdminMessageColorErr(false);
        setTheme({
          ...theme,
          adminMessageColor: e.target.value
        });
        break;
      case "businessText":
        setBusinessTextErr(false);
        setTheme({
          ...theme,
          businessText: e.target.value
        });
        break;
      case "businessLink":
        setBusinessLinkErr(false);
        setTheme({
          ...theme,
          businessLink: e.target.value
        });
        break;
      case "businessColor":
        setBusinessColorErr(false);
        setTheme({
          ...theme,
          businessColor: e.target.value
        });
        break;
      case "answerColor":
        setAnswerColorErr(false);
        setTheme({
          ...theme,
          answerColor: e.target.value
        });
        break;
      case "answerBgColor":
        setAnswerBgColorErr(false);
        setTheme({
          ...theme,
          answerBgColor: e.target.value
        });
        break;
      case "fileStorage":
        setTheme({
          ...theme,
          fileStorage: e.target.files[0]
        });
        break;
      default: break;
    }
  };

  const fetchCreate = async () => {
    try {
      /* File Upload */
      let formData = new FormData();
      if(id) {
        formData.append("id", id);
      }
      formData.append("teamId", theme.teamId);
      formData.append("title", theme.title);
      formData.append("noticeColor", theme.noticeColor);
      formData.append("noticeBgColor", theme.noticeBgColor);
      formData.append("noticeSize", theme.noticeSize);
      formData.append("backgroundColor", theme.backgroundColor);
      formData.append("timeColor", theme.timeColor);
      formData.append("timeSize", theme.timeSize);
      formData.append("messageColor", theme.messageColor);
      formData.append("messageSize", theme.messageSize);
      formData.append("messageLineColor", theme.messageLineColor);
      formData.append("footerBgColor", theme.footerBgColor);
      formData.append("nicknameColor", theme.nicknameColor);
      formData.append("nicknameSize", theme.nicknameSize);
      formData.append("sendBtnBgColor", theme.sendBtnBgColor);
      formData.append("sendBtnColor", theme.sendBtnColor);
      formData.append("adminNicknameColor", theme.adminNicknameColor);
      formData.append("adminMessageColor", theme.adminMessageColor);
      formData.append("businessText", theme.businessText);
      formData.append("businessLink", theme.businessLink);
      formData.append("businessColor", theme.businessColor);
      formData.append("answerColor", theme.answerColor);
      formData.append("answerBgColor", theme.answerBgColor);
      if(theme.fileStorage) {
        formData.append("fileStorage", theme.fileStorage);
      }

      await create(formData).unwrap();
      return navigate("/theme");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          console.log(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onCreate = () => {
    if (!theme.teamId) {
      scrollToRef(refTeamId);
      setTeamIdErr(true);
    }
    if (!theme.title) {
      scrollToRef(refTitle);
      setTitleErr(true);
    }
    if (!theme.noticeColor) {
      setNoticeColorErr(true);
    }
    if (!theme.noticeBgColor) {
      setNoticeBgColorErr(true);
    }
    if (!theme.backgroundColor) {
      setBackgroundColorErr(true);
    }
    if (!theme.timeColor) {
      setTimeColorErr(true);
    }
    if (!theme.messageColor) {
      setMessageColorErr(true);
    }
    if (!theme.messageLineColor) {
      setMessageLineColorErr(true);
    }
    if (!theme.footerBgColor) {
      setFooterBgColorErr(true);
    }
    if (!theme.nicknameColor) {
      setNicknameColorErr(true);
    }
    if (!theme.sendBtnBgColor) {
      setSendBtnBgColorErr(true);
    }
    if (!theme.sendBtnColor) {
      setSendBtnColorErr(true);
    }
    if (!theme.adminNicknameColor) {
      setAdminNicknameColorErr(true);
    }
    if (!theme.adminMessageColor) {
      setAdminMessageColorErr(true);
    }
    if (!theme.businessText) {
      setBusinessTextErr(true);
    }
    if (!theme.businessLink) {
      setBusinessLinkErr(true);
    }
    if (!theme.businessColor) {
      setBusinessColorErr(true);
    }
    if (!theme.answerColor) {
      setAnswerColorErr(true);
    }
    if (!theme.answerBgColor) {
      setAnswerBgColorErr(true);
    }
    if (
        !theme.teamId ||
        !theme.title ||
        !theme.noticeColor ||
        !theme.noticeBgColor ||
        !theme.backgroundColor ||
        !theme.timeColor ||
        !theme.messageColor ||
        !theme.messageLineColor ||
        !theme.footerBgColor ||
        !theme.nicknameColor ||
        !theme.sendBtnBgColor ||
        !theme.sendBtnColor ||
        !theme.adminNicknameColor ||
        !theme.adminMessageColor ||
        !theme.businessText ||
        !theme.businessLink ||
        !theme.businessColor ||
        !theme.answerColor ||
        !theme.answerBgColor
    ) {
      return false;
    }

    fetchCreate();
  };

  const onCheckEnter = (e) => {
    if (e.key === 'Enter') {
      onCreate();
    }
  }

  return (
      <FadeMotion>
        <FlexBox>
          <ColLg5>
            <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}
                   onKeyPress={onCheckEnter}>
              <Typography
                  sx={{mb: 4}}
                  variant="h5"
                  component="div"
                  gutterBottom
              >
                {id ? "테마 수정" : "테마 만들기"}
              </Typography>
              <FormControl
                  fullWidth
                  sx={{mb: 2}}
                  error={teamIdErr}>
                <InputLabel id="teamId-label">팀 선택</InputLabel>
                <Select
                    onChange={onChangeTheme}
                    value={theme.teamId}
                    ref={refTeamId}
                    labelId="teamId-label"
                    id="teamId"
                    name="teamId"
                    label="팀 선택">
                  {teamOption}
                </Select>
                {teamIdErr ? <FormHelperText>필수로 입력해야 합니다.</FormHelperText> : ''}
              </FormControl>
              <TextField
                  onChange={onChangeTheme}
                  ref={refTitle}
                  value={theme.title}
                  error={titleErr}
                  helperText={titleErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 테마 제목 (테마 구분에 사용됩니다.)"
                  id="title"
                  name="title"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.noticeColor}
                  error={noticeColorErr}
                  helperText={noticeColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 공지사항 글자 색상"
                  name="noticeColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.noticeBgColor}
                  error={noticeBgColorErr}
                  helperText={noticeBgColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 공지사항 배경 색상"
                  name="noticeBgColor"
                  fullWidth/>
              <Box sx={{mb: 3}}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  공지사항 글자 사이즈
                </Typography>
                <Slider
                    onChange={onChangeTheme}
                    value={theme.noticeSize}
                    sx={{color: THEME.color}}
                    min={11}
                    max={28}
                    name="noticeSize"
                    aria-label="Default"
                    valueLabelDisplay="auto" />
              </Box>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.backgroundColor}
                  error={backgroundColorErr}
                  helperText={backgroundColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 채팅 배경 색상"
                  name="backgroundColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.timeColor}
                  error={timeColorErr}
                  helperText={timeColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 채팅 날짜 글자 색상"
                  name="timeColor"
                  fullWidth/>
              <Box sx={{mb: 3}}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  채팅 날짜 글자 사이즈
                </Typography>
                <Slider
                    onChange={onChangeTheme}
                    value={theme.timeSize}
                    sx={{color: THEME.color}}
                    min={11}
                    max={28}
                    name="timeSize"
                    aria-label="Default"
                    valueLabelDisplay="auto" />
              </Box>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.messageColor}
                  error={messageColorErr}
                  helperText={messageColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 채팅 글자 색상"
                  name="messageColor"
                  fullWidth/>
              <Box sx={{mb: 3}}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  채팅 글자 사이즈
                </Typography>
                <Slider
                    onChange={onChangeTheme}
                    value={theme.messageSize}
                    sx={{color: THEME.color}}
                    min={11}
                    max={28}
                    name="messageSize"
                    aria-label="Default"
                    valueLabelDisplay="auto" />
              </Box>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.messageLineColor}
                  error={messageLineColorErr}
                  helperText={messageLineColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 채팅 라인 색상"
                  name="messageLineColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.footerBgColor}
                  error={footerBgColorErr}
                  helperText={footerBgColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 채팅 푸터 배경 색상"
                  name="footerBgColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.nicknameColor}
                  error={nicknameColorErr}
                  helperText={nicknameColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 닉네임 색상"
                  name="nicknameColor"
                  fullWidth/>
              <Box sx={{mb: 3}}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  닉네임 글자 사이즈
                </Typography>
                <Slider
                    onChange={onChangeTheme}
                    value={theme.nicknameSize}
                    sx={{color: THEME.color}}
                    min={11}
                    max={28}
                    name="nicknameSize"
                    aria-label="Default"
                    valueLabelDisplay="auto" />
              </Box>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.sendBtnBgColor}
                  error={sendBtnBgColorErr}
                  helperText={sendBtnBgColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 전송 버튼 색상"
                  name="sendBtnBgColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.sendBtnColor}
                  error={sendBtnColorErr}
                  helperText={sendBtnColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 전송 버튼 글자 색상"
                  name="sendBtnColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.adminNicknameColor}
                  error={adminNicknameColorErr}
                  helperText={adminNicknameColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 관리자 닉네임 색상"
                  name="adminNicknameColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.adminMessageColor}
                  error={adminMessageColorErr}
                  helperText={adminMessageColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  inputProps={{ maxLength: 6 }}
                  label="* 관리자 메세지 색상"
                  name="adminMessageColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.businessText}
                  error={businessTextErr}
                  helperText={businessTextErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 로고 글자"
                  name="businessText"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.businessLink}
                  error={businessLinkErr}
                  helperText={businessLinkErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 로고 링크"
                  name="businessLink"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.businessColor}
                  error={businessColorErr}
                  helperText={businessColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 로고 글자 색상"
                  name="businessColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.answerColor}
                  error={answerColorErr}
                  helperText={answerColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 답변 글자 색상"
                  name="answerColor"
                  fullWidth/>
              <TextField
                  onChange={onChangeTheme}
                  value={theme.answerBgColor}
                  error={answerBgColorErr}
                  helperText={answerBgColorErr ? "필수로 입력해야 합니다." : ''}
                  sx={{mb: 3}}
                  label="* 답변 배경 색상"
                  name="answerBgColor"
                  fullWidth/>

              <Box>
                <Typography variant="subtitle1" gutterBottom component="div" sx={{mb: 2}}>
                  아이콘 사이즈는 <b>최소(20px X 20px)</b><br/>
                  <b>(권장) 최대(28px X 28px)</b> 로 업로드 해주세요.
                </Typography>
                <Box sx={{mb: 3}}>
                  <input name="fileStorage" type="file" onChange={onChangeTheme} />
                </Box>
                <Box>
                  <ReactAvatarEditor
                      border={0}
                      scale={1}
                      width={100}
                      height={100}
                      position={{x: 0.5, y: 0.5}}
                      rotate={0}
                      borderRadius={0}
                      image={theme.fileStorageUpdate ? SERVER_URL + theme.fileStorageUpdate.fileDownloadUri : theme.fileStorage}
                  />
                </Box>
              </Box>

              <ThemeLoadingButton
                  onClick={onCreate}
                  sx={{mt: 3}}
                  loading={isLoading}
                  variant="contained"
              >SAVE</ThemeLoadingButton>
            </Paper>
          </ColLg5>
          <ColLg5>
            <Paper elevation={1} sx={{
              mb: 2,
              pl: 2,
              pr: 2,
              pt: 2,
              pb: 2,
              height: 550,
              position: "sticky",
              top: "96px"
            }}>
              <Container theme={{backgroundColor: theme.footerBgColor}}>
                <Wrapper>
                  <MessageContainer
                      theme={{backgroundColor: theme.backgroundColor}}>
                    <NoticeWrapper>
                      <NoticeBox
                          theme={{
                            backgroundColor: theme.noticeBgColor,
                            fontColor: theme.noticeColor,
                            fontSize: theme.noticeSize
                          }}>
                        공지사항 입니다.
                      </NoticeBox>
                    </NoticeWrapper>
                    <MessageWrapper>
                      <Message align={"left"}>
                        <MessageContent>
                          <MessageTimestamp
                              theme={{
                                fontColor: theme.timeColor,
                                fontSize: theme.timeSize
                              }}>
                            2022-05-30 03:45:59
                          </MessageTimestamp>
                          <>
                            <MessageProfile
                                theme={{
                                  fontColor: theme.nicknameColor,
                                  fontSize: theme.nicknameSize
                                }}>
                              쭌프로
                            </MessageProfile>
                            <MessageLine
                                theme={{
                                  fontColor: theme.nicknameColor,
                                  fontSize: theme.nicknameSize
                                }}>
                              :
                            </MessageLine>
                          </>
                          <MessageText
                              theme={{
                                fontColor: theme.messageColor,
                                fontSize: theme.messageSize
                              }}>
                            안녕하세요 쭌프로입니다.
                          </MessageText>
                        </MessageContent>
                      </Message>
                      <Message align={"right"}>
                        <MessageContent>
                          <MessageTimestamp
                              theme={{
                                fontColor: theme.timeColor,
                                fontSize: theme.timeSize
                              }}>
                            2022-05-30 03:45:59
                          </MessageTimestamp>
                          <MessageText
                              theme={{
                                fontColor: theme.messageColor,
                                fontSize: theme.messageSize
                              }}>
                            네오다임 채팅서버에 오신것을 환영합니다.
                          </MessageText>
                        </MessageContent>
                      </Message>
                      <Message align={"left"}>
                        <MessageContent>
                          <MessageTimestamp
                              theme={{
                                fontColor: theme.timeColor,
                                fontSize: theme.timeSize
                              }}>
                            2022-05-30 03:45:59
                          </MessageTimestamp>
                          <>
                            <MessageProfile
                                theme={{
                                  fontColor: theme.adminNicknameColor,
                                  fontSize: theme.nicknameSize
                                }}>
                              {
                                (theme.fileStorage || theme.fileStorageUpdate) ?
                                <span>
                                  <ReactAvatarEditor
                                      border={0}
                                      scale={1}
                                      width={28}
                                      height={28}
                                      position={{x: 0.5, y: 0.5}}
                                      rotate={0}
                                      borderRadius={0}
                                      image={theme.fileStorageUpdate ? SERVER_URL + theme.fileStorageUpdate.fileDownloadUri : theme.fileStorage}
                                      style={{marginBottom: -5, marginRight: 5}}
                                  />
                                </span>
                                :
                                ""
                              }
                              <span>
                                관리자
                              </span>
                            </MessageProfile>
                            <MessageLine
                                theme={{
                                  fontColor: theme.adminNicknameColor,
                                  fontSize: theme.nicknameSize
                                }}>
                              :
                            </MessageLine>
                          </>
                          <MessageText
                              theme={{
                                fontColor: theme.adminMessageColor,
                                fontSize: theme.messageSize
                              }}>
                            관리자 채팅입니다.
                          </MessageText>
                        </MessageContent>
                      </Message>
                      <Answer
                          theme={{
                            fontColor: theme.answerColor,
                            fontSize: theme.messageSize,
                            backgroundColor: theme.answerBgColor
                          }}>
                        <Question>
                          <div>쭌프로에게 답변</div>
                          <div>안녕하세요 쭌프로입니다.</div>
                        </Question>
                        <AnswerBox>
                          <SubdirectoryArrowRightIcon fill={theme.answerColor}/>
                          {
                            (theme.fileStorage || theme.fileStorageUpdate) ?
                                <span>
                                  <ReactAvatarEditor
                                      border={0}
                                      scale={1}
                                      width={28}
                                      height={28}
                                      position={{x: 0.5, y: 0.5}}
                                      rotate={0}
                                      borderRadius={0}
                                      image={theme.fileStorageUpdate ? SERVER_URL + theme.fileStorageUpdate.fileDownloadUri : theme.fileStorage}
                                      style={{marginBottom: -5, marginRight: 5}}
                                  />
                                </span>
                                :
                                ""
                          }
                          쭌프로 :
                          답변입니다.
                        </AnswerBox>
                      </Answer>
                    </MessageWrapper>
                  </MessageContainer>
                  <MessageInputContainer
                      theme={{
                        backgroundColor: theme.messageLineColor,
                      }}>
                    <MessageInputWrapper sx={{display: 'flex'}}>
                      <MessageTextArea
                          placeholder={"메시지 작성 후 엔터키를 누르세요"}
                          name={"message"}
                      />
                      <SendButton
                          variant="contained"
                          theme={{
                            backgroundColor: theme.sendBtnBgColor,
                            fontColor: theme.sendBtnColor
                          }}
                      >
                        전송
                      </SendButton>
                    </MessageInputWrapper>
                  </MessageInputContainer>
                </Wrapper>
                <BusinessLink
                    theme={{
                      fontColor: theme.businessColor,
                    }}
                    href={theme.businessLink}
                    target={"_blank"}
                    underline="none">
                  {theme.businessText}
                </BusinessLink>
              </Container>
            </Paper>
          </ColLg5>
        </FlexBox>
      </FadeMotion>
  );
}

export default ThemeCreate;