import Swal from "sweetalert2";
import {THEME} from "../components/layouts/THEME";

export const scrollToRef = (ref) => window.scrollTo(0,
    ref.current.offsetTop - 200);

/* Swal Custom */
export const ToastCustom = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  background: THEME.color,
  color: "#ffffff",
  customClass: {
    "container": "100"
  },
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
