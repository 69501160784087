import {Link} from 'react-router-dom';
import {styled} from "@mui/system";
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {THEME} from "./THEME";

export const LinkButton = styled(Link)`
  text-decoration-line: none;
  color: #333;
  width: 100%;
`;

export const HeaderWrapper = styled("div")`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
`;

export const AdminContainer = styled(Container)`
  padding: 1rem 1.5rem;
  padding-top: 6rem;
`;

export const FlexContentBetween = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBox = styled("div")`
  width: 100%;
  max-width: 300px;
`;

export const ThemeButton = styled(Button)`
  background-color: ${THEME.color};
  
  &:hover {
    background-color: ${THEME.hover};
  }
`;

export const ThemeLoadingButton = styled(LoadingButton)`
  background-color: ${THEME.color};
  
  &:hover {
    background-color: ${THEME.hover};
  }
`;