import { apiSlice } from "../../app/api/apiSlice";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const chatroomAdapter = createEntityAdapter()

/* 기본 */
const initialState = chatroomAdapter.getInitialState();

export const chatroomApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getChatroomEnter: builder.query({
      query: (arg) => ({
        url: '/api/chatroom/enter/' + arg.id,
        params: arg.patch
      })
    }),
    getChatroomList: builder.query({
      query: (arg) => ({
        url:  (arg.state ? '/api/chatroom/' : '/api/chatroom/end/')  + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = chatroomAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return chatroomAdapter.setAll(customState, responseData.chatRoomList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Chatroom', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Chatroom', id }))
      ],
      keepUnusedDataFor: 0
    }),
    createChatroom: builder.mutation({
      query: arg => ({
        url: '/api/chatroom/create',
        method: 'POST',
        body: { ...arg }
      }),
      invalidatesTags: [
        { type: 'Chatroom', id: "LIST" }
      ],
      keepUnusedDataFor: 60
    }),
    destroyChatroom: builder.mutation({
      query: (arg) => ({
        url: '/api/chatroom/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Chatroom', id: arg.id }
      ],
      keepUnusedDataFor: 60
    }),
    getChatroomById: builder.query({
      query: (arg) => '/api/chatroom/create/' + arg,
      providesTags: (result, error, arg) => [
        { type: 'Chatroom', id: "LIST" }
      ],
      keepUnusedDataFor: 60
    }),
    getChatroomTitleById: builder.query({
      query: (arg) => '/api/chatroom/title/' + arg,
      providesTags: (result, error, arg) => [
        { type: 'ChatroomTitle', id: "TITLE" }
      ],
      keepUnusedDataFor: 60
    }),
    getChattingList: builder.query({
      query: (arg) => ({
        url:  '/api/message/' + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = chatroomAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return chatroomAdapter.setAll(customState, responseData.chattingList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Chatting', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Chatting', id }))
      ],
      keepUnusedDataFor: 0
    }),
    destroyChatting: builder.mutation({
      query: (arg) => ({
        url: '/api/message/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Chatting', id: arg.id }
      ]
    }),
  })
});

export const {
  useGetChatroomEnterQuery,
  useGetChatroomListQuery,
  useCreateChatroomMutation,
  useDestroyChatroomMutation,
  useGetChatroomByIdQuery,
  useGetChatroomTitleByIdQuery,
  useGetChattingListQuery,
  useDestroyChattingMutation
} = chatroomApiSlice;

// returns the query result object
export const selectChatroomResult = chatroomApiSlice.endpoints.getChatroomList.select();

// Creates memoized selector
const selectChatroomData = createSelector(
    selectChatroomResult,
    chatroomResult => chatroomResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllChatroom,
  selectById: selectPostById,
  selectIds: selectPostIds
  // Pass in a selector that returns the chatroom slice of state
} = chatroomAdapter.getSelectors(state => selectChatroomData(state) ?? initialState);