import React, {useEffect, useState} from 'react';
import {Box, Container} from "@mui/material";
import Logo from "../../assets/img/neodigm_logo.jpg";
import {
  FormWrapper,
  ImgBox,
  LinkButton
} from "./Auth.style";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

import {motion, MotionConfig} from "framer-motion";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../features/auth/authSlice";
import {useGetTeamListAllQuery} from "../../features/team/teamApiSlice";
import JoinForm from "../../components/forms/JoinForm";
import {useCreateAuthMutation} from "../../features/auth/authApiSlice";
import {ThemeLoadingButton} from "../../components/layouts/Layout.style";
import {THEME} from "../../components/layouts/THEME";

function Join() {
  const [teamId, setTeamId] = useState("");
  const [teamIdErr, setTeamIdErr] = useState(null);
  const [teamIdErrText, setTeamIdErrText] = useState("");

  const [nickname, setNickname] = useState("");
  const [nicknameErr, setNicknameErr] = useState(null);
  const [nicknameErrText, setNicknameErrText] = useState("");

  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState(null);
  const [usernameErrText, setUsernameErrText] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(null);
  const [passwordErrText, setPasswordErrText] = useState("");

  const navigate = useNavigate();

  /* Team List 를 가져옵니다. */
  const {
    data: teamList,
    isSuccess: teamIsSuccess
  } = useGetTeamListAllQuery();

  /* Auth Join 요청 */
  const [join, {isLoading}] = useCreateAuthMutation();

  /* Auth 상태체크 */
  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    if(token) {
      navigate("/chatroom");
    }
  });

  const fetchJoin = async () => {
    try {
      const joinData = {
        teamId,
        nickname,
        username,
        password
      };

      await join(joinData).unwrap();
      return navigate("/");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          errorInit(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onChangeTeamId = (e) => {
    setTeamIdErr(false);
    setTeamId(e.target.value);
  };

  const onChangeNickname = (e) => {
    setNicknameErr(false);
    setNickname(e.target.value);
  };

  const onChangeUsername = (e) => {
    setUsernameErr(false);
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPasswordErr(false);
    setPassword(e.target.value);
  };

  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      onJoin();
    }
  }

  const onJoin = () => {
    if (!teamId) {
      errorInit("teamId", "팀을 선택해 주세요.");
    }
    if (!nickname) {
      errorInit("nickname", "닉네임을 입력해 주세요.");
    }
    if (!username) {
      errorInit("username", "아이디를 입력해 주세요.");
    }
    if (!password) {
      errorInit("password", "비밀번호를 입력해 주세요.");
    }
    if (!teamId || !nickname || !username || !password) {
      return false;
    }

    fetchJoin();
  };

  const errorInit = (type, message) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "teamId":
        setTeamIdErr(true);
        setTeamIdErrText(message);
        break;
      case "nickname":
        setNicknameErr(true);
        setNicknameErrText(message);
        break;
      case "username":
        setUsernameErr(true);
        setUsernameErrText(message);
        break;
      case "password":
        setPasswordErr(true);
        setPasswordErrText(message);
        break;
    }
  }

  return (
      <div style={{overflow: "hidden"}}>
        <MotionConfig transition={{duration: 0.3}}>
          <motion.div
              initial={{opacity: 0, x: 50}}
              animate={{opacity: 1, x: 0}}
              exit={{x: 50, opacity: 0}}
          >
            <Container maxWidth="sm">
              <FormWrapper onKeyPress={onCheckEnter}>
                <Box>
                  <ImgBox>
                    <img src={Logo} alt="네오다임"/>
                  </ImgBox>
                  <JoinForm
                      teamId={teamId}
                      teamIdErr={teamIdErr}
                      teamIdErrText={teamIdErrText}
                      nickname={nickname}
                      nicknameErr={nicknameErr}
                      nicknameErrText={nicknameErrText}
                      username={username}
                      usernameErr={usernameErr}
                      usernameErrText={usernameErrText}
                      passwordErr={passwordErr}
                      passwordErrText={passwordErrText}
                      onChangeTeamId={onChangeTeamId}
                      onChangeNickname={onChangeNickname}
                      onChangeUsername={onChangeUsername}
                      onChangePassword={onChangePassword}
                      teamList={teamList}
                      teamIsSuccess={teamIsSuccess}
                  />
                  <ThemeLoadingButton
                      onClick={onJoin}
                      loading={isLoading}
                      variant="contained"
                      sx={{mb: 1}}
                      fullWidth
                      disableElevation>
                    계정 만들기
                  </ThemeLoadingButton>
                  <LinkButton to="/">
                    <Button
                        sx={{color: THEME.color, borderColor: THEME.color}}
                        variant="outlined"
                        fullWidth>로그인</Button>
                  </LinkButton>
                </Box>
              </FormWrapper>
            </Container>
          </motion.div>
        </MotionConfig>
      </div>
  );
}

export default Join;