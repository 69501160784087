import { apiSlice } from "../../app/api/apiSlice";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const authAdapter = createEntityAdapter()

/* 기본 */
const initialState = authAdapter.getInitialState();

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/api/auth/login',
        method: 'POST',
        body: { ...credentials }
      })
    }),
    getClientListAll: builder.query({
      query: () => ({
        url:  "/api/auth/client"
      }),
      keepUnusedDataFor: 60
    }),
    getAuthList: builder.query({
      query: (arg) => ({
        url:  '/api/auth/' + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = authAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return authAdapter.setAll(customState, responseData.authList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Auth', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Auth', id }))
      ],
      keepUnusedDataFor: 60
    }),
    getAuthById: builder.query({
      query: (arg) => '/api/auth/create/' + arg,
      providesTags: (result, error, arg) => [
        { type: 'Auth', id: "LIST" }
      ],
      keepUnusedDataFor: 60
    }),
    getHealthAuth:  builder.query({
      query: () => '/api/auth/health'
    }),
    createAuth: builder.mutation({
      query: arg => ({
        url: '/api/auth/join',
        method: 'POST',
        body: { ...arg }
      }),
      invalidatesTags: [
        { type: 'Auth', id: "LIST" }
      ]
    }),
    destroyAuth: builder.mutation({
      query: (arg) => ({
        url: '/api/auth/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Auth', id: arg.id }
      ]
    })
  })
})

export const {
  useLoginMutation,
  useGetClientListAllQuery,
  useGetClientListQuery,
  useGetAuthListQuery,
  useGetAuthByIdQuery,
  useGetHealthAuthQuery,
  useCreateAuthMutation,
  useDestroyAuthMutation,
} = authApiSlice;

// returns the query result object
export const selectAuthResult = authApiSlice.endpoints.getAuthList.select();

// Creates memoized selector
const selectAuthData = createSelector(
    selectAuthResult,
    authResult => authResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAuth,
  selectById: selectAuthById,
  selectIds: selectAuthIds
  // Pass in a selector that returns the auth slice of state
} = authAdapter.getSelectors(state => selectAuthData(state) ?? initialState);