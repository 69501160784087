import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: 'auth',
  initialState: {username: null, token: null, userRole:null, teamId: null, nickname: null},
  reducers: {
    setCredentials: (state, action) => {
      const {username, accessToken, userRole, teamId, nickname} = action.payload;
      state.username = username;
      state.token = accessToken;
      state.userRole = userRole;
      state.teamId = teamId;
      state.nickname = nickname;
    },
    logOut: (state, action) => {
      state.username = null;
      state.token = null;
      state.userRole = null;
      state.teamId = null;
      state.nickname = null;
    }
  },
})

export const {setCredentials, logOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.username;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRole = (state) => state.auth.userRole;
export const selectCurrentTeamId = (state) => state.auth.teamId;
export const selectCurrentNickname = (state) => state.auth.nickname;