import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {logOut} from '../../features/auth/authSlice';
import {SERVER_URL} from '../../routes.js';

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
  credentials: 'include',
  prepareHeaders: (headers, {getState}) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  /* 서버에서 500 리턴으로 인하여 error message 로 조건문 */
  /*if (result?.error?.originalStatus === 403) {*/
  if (result?.error?.data.message === "TOKEN_ERROR") {
    // console.log('sending refresh token');
    // send refresh token to get new access token
    // refresh Token 인증을 사용하지 않음 바로 로그아웃
    // const refreshResult = await baseQuery('/refresh', api, extraOptions);
    /*if (refreshResult?.data) {*/
    if (false) {
      /*const user = api.getState().auth.user;*/
      // store the new token
      /*api.dispatch(setCredentials({...refreshResult.data, user}));*/
      // retry the original query with new access token
      /*result = await baseQuery(args, api, extraOptions);*/
    } else {
      api.dispatch(logOut());
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({})
})