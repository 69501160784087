import React, {useEffect, useState} from 'react'
import FadeMotion from "../../components/layouts/FadeMotion";
import {
  Paper,
  Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {
  useGetTeamListAllQuery
} from "../../features/team/teamApiSlice";
import JoinForm from "../../components/forms/JoinForm";
import {
  useCreateAuthMutation,
  useGetAuthByIdQuery
} from "../../features/auth/authApiSlice";
import {ThemeLoadingButton} from "../../components/layouts/Layout.style";

function AuthCreate() {
  /* Update 시 id 값 존재함 */
  const {id} = useParams();

  const [teamId, setTeamId] = useState("");
  const [teamIdErr, setTeamIdErr] = useState(null);
  const [teamIdErrText, setTeamIdErrText] = useState("");

  const [nickname, setNickname] = useState("");
  const [nicknameErr, setNicknameErr] = useState(null);
  const [nicknameErrText, setNicknameErrText] = useState("");

  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState(null);
  const [usernameErrText, setUsernameErrText] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(null);
  const [passwordErrText, setPasswordErrText] = useState("");

  const navigate = useNavigate();

  /* Team List 를 가져옵니다. */
  const {
    data: teamList,
    isSuccess: teamIsSuccess
  } = useGetTeamListAllQuery();

  /* Auth Join 요청 */
  const [join, {isLoading}] = useCreateAuthMutation();

  const {
    data: auth,
    isSuccess: authIsSuccess
  } = useGetAuthByIdQuery(id, {skip: (id === undefined)});

  useEffect(() => {
    if (authIsSuccess) {
      setTeamId(auth.team.id);
      setNickname(auth.nickname);
      setUsername(auth.username);
    }
  }, [auth, authIsSuccess]);

  const fetchJoin = async () => {
    try {
      const joinData = {
        id,
        teamId,
        nickname,
        username,
        password
      };

      await join(joinData).unwrap();
      return navigate("/auth");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          errorInit(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onChangeTeamId = (e) => {
    setTeamIdErr(false);
    setTeamId(e.target.value);
  };

  const onChangeNickname = (e) => {
    setNicknameErr(false);
    setNickname(e.target.value);
  };

  const onChangeUsername = (e) => {
    setUsernameErr(false);
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPasswordErr(false);
    setPassword(e.target.value);
  };

  const onCheckEnter = (e) => {
    if (e.key === 'Enter') {
      onJoin();
    }
  }

  const onJoin = () => {
    if (!teamId) {
      errorInit("teamId", "팀을 선택해 주세요.");
    }
    if (!nickname) {
      errorInit("nickname", "닉네임을 입력해 주세요.");
    }
    if (!username) {
      errorInit("username", "아이디를 입력해 주세요.");
    }
    if (!teamId || !nickname || !username) {
      return false;
    }

    fetchJoin();
  };

  const errorInit = (type, message) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "teamId":
        setTeamIdErr(true);
        setTeamIdErrText(message);
        break;
      case "nickname":
        setNicknameErr(true);
        setNicknameErrText(message);
        break;
      case "username":
        setUsernameErr(true);
        setUsernameErrText(message);
        break;
      case "password":
        setPasswordErr(true);
        setPasswordErrText(message);
        break;
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}
               onKeyPress={onCheckEnter}>
          <Typography
              sx={{mb: 4}}
              variant="h5"
              component="div"
              gutterBottom
          >
            {id ? "회원 수정" : "회원 만들기"}
          </Typography>
          <JoinForm
              teamId={teamId}
              teamIdErr={teamIdErr}
              teamIdErrText={teamIdErrText}
              nickname={nickname}
              nicknameErr={nicknameErr}
              nicknameErrText={nicknameErrText}
              username={username}
              usernameErr={usernameErr}
              usernameErrText={usernameErrText}
              passwordErr={passwordErr}
              passwordErrText={passwordErrText}
              onChangeTeamId={onChangeTeamId}
              onChangeNickname={onChangeNickname}
              onChangeUsername={onChangeUsername}
              onChangePassword={onChangePassword}
              teamList={teamList}
              teamIsSuccess={teamIsSuccess}
          />
          <ThemeLoadingButton
              onClick={onJoin}
              sx={{mt: 3}}
              loading={isLoading}
              variant="contained"
          >SAVE</ThemeLoadingButton>
        </Paper>
      </FadeMotion>
  );
}

export default AuthCreate;