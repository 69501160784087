import React from 'react'
import {
  ClearButton
} from "./ChatRoom.style"
import DeleteIcon from "@mui/icons-material/Delete"
import Swal from "sweetalert2"

function ClearChatting({
  onClear
}) {

  const _onClick = () => {

    Swal.fire({
      title: '채팅 기록이 모두 사라집니다.',
      text: "정말로 초기화를 진행합니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '초기화'
    }).then((result) => {
      if (result.isConfirmed) {
        onClear();
        Swal.fire(
            'Clear!',
            '채팅 기록이 초기화 되었습니다.',
            'success'
        )
      }
    });
  }

  return (
      <ClearButton
          variant="contained"
          startIcon={<DeleteIcon/>}
          size="small"
          onClick={_onClick}
      >
        초기화
      </ClearButton>
  );
}

export default ClearChatting;