import {styled} from "@mui/system";
import {Link} from 'react-router-dom';

export const FormWrapper = styled("form")`
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 3vh auto 0;
    background-color: hsla(0,0%,100%,.92);
    padding: 40px 20px;
    width: 100%;
    max-width: 360px;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
`;

export const ImgBox = styled("h1")`
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
`;

export const LinkButton = styled(Link)`
  text-decoration-line: none;
`;