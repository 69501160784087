import React, {useEffect, useState} from 'react';
import {
  FlexContentBetween, SearchBox
} from "../../components/layouts/Layout.style";
import {IconButton, Input, Paper} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FadeMotion from "../../components/layouts/FadeMotion";
import {useGetIpListQuery, useDestroyIpMutation} from "../../features/ip/ipApiSlice";
import IpTable from "../../components/tables/IpTable";

function IpList() {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [cate] = useState("ip");
  const [search, setSearch] = useState("");

  const [destroy] = useDestroyIpMutation();

  const {
    data: ipList,
    isSuccess
  } = useGetIpListQuery({
    "id": currentPage,
    "patch": {
      "cate": cate,
      "search": search
    }
  });

  useEffect(() => {
    if(isSuccess) {
      setTotalItems(ipList.totalItems);
    }
  }, [isSuccess, ipList]);

  const onChangeCurrentPage = (e) => {
    setCurrentPage(e);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    e.preventDefault();
  }

  const onDestroy = async (id) => {
    try {
      await destroy(id).unwrap();
    } catch (e) {
      if (e.originalStatus === 400) {
        return e;
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}>
          <FlexContentBetween>
            <SearchBox>
              <FlexContentBetween>
                <Input
                    onChange={onChangeSearch}
                    placeholder="IP 검색"
                    fullWidth/>
                <IconButton variant="contained">
                  <SearchIcon color={"primary"}/>
                </IconButton>
              </FlexContentBetween>
            </SearchBox>
          </FlexContentBetween>
        </Paper>
        <IpTable
            ipList={ipList}
            totalItems={totalItems}
            currentPage={currentPage}
            search={search}
            onChangeCurrentPage={onChangeCurrentPage}
            onDestroy={onDestroy}
            isSuccess={isSuccess}
        />
      </FadeMotion>
  )
}

export default IpList;