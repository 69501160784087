import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  logOut,
  selectCurrentUser
} from '../../features/auth/authSlice';
import AdminMenu from "./AdminMenu";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {PATH} from "../../routes/PATH";
import {THEME} from "./THEME";

export default function AdminHeader() {
  const {pathname} = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [pageTitle, setPageTitle] = useState("");

  const username = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const onLogout = () => {
    dispatch(logOut());
    handleMobileMenuClose();
  };

  useEffect(() => {
    // Menu Title
    if(pathname.includes(PATH.team.path.index)) {
      setPageTitle(PATH.team.title.index);
    } else if(pathname.includes(PATH.auth.path.index)) {
      setPageTitle(PATH.auth.title.index);
    } else if(pathname.includes(PATH.chatroom.path.indexEnd)) {
      setPageTitle(PATH.chatroom.title.indexEnd);
    } else if(pathname.includes(PATH.chatroom.path.index)) {
      setPageTitle(PATH.chatroom.title.index);
    } else if(pathname.includes(PATH.theme.path.index)) {
      setPageTitle(PATH.theme.title.index);
    } else if(pathname.includes(PATH.ip.path.index)) {
      setPageTitle(PATH.ip.title.index);
    } else {
      setPageTitle("관리자 페이지");
    }
  }, [pageTitle, pathname]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
      <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
      >
        <MenuItem>{username}</MenuItem>
        <MenuItem onClick={onLogout}>로그아웃</MenuItem>
      </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
      <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
      >
        <MenuItem>{username}</MenuItem>
        <MenuItem onClick={onLogout}>로그아웃</MenuItem>
      </Menu>
  );

  return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{backgroundColor: THEME.color}}>
          <Toolbar>
            <AdminMenu/>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {pageTitle}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
  );
}
