import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {LinkButton} from "./Layout.style";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChatIcon from '@mui/icons-material/Chat';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import FolderIcon from '@mui/icons-material/Folder';
import BlockIcon from '@mui/icons-material/Block';
import {PATH} from "../../routes/PATH";
import {useSelector} from "react-redux";
import {
  selectCurrentRole
} from "../../features/auth/authSlice";

export default function AdminMenu() {
  const menu = [
    {id: 1, role: ["ROLE_ADMIN"], name: PATH.team.title.index, link: PATH.team.path.index},
    {id: 2, role: ["ROLE_ADMIN", "ROLE_USER"], name: PATH.auth.title.index, link: PATH.auth.path.index},
    {id: 3, role: ["ROLE_ADMIN", "ROLE_USER", "ROLE_CLIENT"], name: PATH.chatroom.title.index, link: PATH.chatroom.path.index},
    {id: 4, role: ["ROLE_ADMIN", "ROLE_USER"], name: PATH.chatroom.title.indexEnd, link: PATH.chatroom.path.indexEnd},
    {id: 5, role: ["ROLE_ADMIN", "ROLE_USER"], name: PATH.theme.title.index, link: PATH.theme.path.index},
    {id: 6, role: ["ROLE_ADMIN", "ROLE_USER", "ROLE_CLIENT"], name: PATH.ip.title.index, link: PATH.ip.path.index},
  ];

  const role = useSelector(selectCurrentRole);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const list = (anchor) => (
      <Box
          sx={{width: 250}}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {menu.map((item, index) => (
              <Box key={index}>
                {
                  item.role.includes(role) ?
                  <ListItem  disablePadding>
                    <LinkButton to={item.link}>
                      <ListItemButton>
                        <ListItemIcon>
                          {
                            item.id === 1 ?
                                <GroupsIcon/>
                                :
                                (item.id === 2 ?
                                        <AccountCircleIcon/>
                                        :
                                        (item.id === 3 ?
                                                <ChatIcon/>
                                                :
                                                (item.id === 4 ?
                                                        <CommentsDisabledIcon/>
                                                        :
                                                        item.id === 5 ?
                                                            <FolderIcon/>
                                                            :
                                                            <BlockIcon/>
                                                )
                                        )
                                )
                          }
                        </ListItemIcon>
                        <ListItemText primary={item.name}/>
                      </ListItemButton>
                    </LinkButton>
                  </ListItem>
                  :
                  null
                }
              </Box>
          ))}
        </List>
      </Box>
  );

  return (
      <>
        <React.Fragment key={"left"}>
          <IconButton
              onClick={toggleDrawer("left", true)}
              sx={{mr: 2}}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
          >
            <MenuIcon/>
          </IconButton>
          <SwipeableDrawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
      </>
  );
}
