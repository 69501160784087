import React, {useEffect, useState} from 'react'
import FadeMotion from "../../components/layouts/FadeMotion";
import {
  FormHelperText, Grid,
  Paper,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  useCreateChatroomMutation,
  useGetChatroomByIdQuery
} from "../../features/chatroom/chatroomApiSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  useGetTeamListAllQuery
} from "../../features/team/teamApiSlice";
import {useGetThemeListAllQuery} from "../../features/theme/themeApiSlice";
import {useGetClientListAllQuery} from "../../features/auth/authApiSlice";
import {
  ThemeButton,
  ThemeLoadingButton
} from "../../components/layouts/Layout.style";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ChipSelect from "../../components/forms/SelectChip";

function ChatroomCreate() {
  /* Update 시 id 값 존재함 */
  const {id} = useParams();

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(null);
  const [titleErrText, setTitleErrText] = useState("");

  const [teamId, setTeamId] = useState("");
  const [teamIdErr, setTeamIdErr] = useState(null);
  const [teamIdErrText, setTeamIdErrText] = useState("");

  const [themeId, setThemeId] = useState("");
  const [themeIdErr, setThemeIdErr] = useState(null);
  const [themeIdErrText, setThemeIdErrText] = useState("");

  const [clientId, setClientId] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState(null);
  const [startDateErrText, setStartDateErrText] = useState("");

  const [endDate, setEndDate] = useState("");
  const [endDateErr, setEndDateErr] = useState(null);
  const [endDateErrText, setEndDateErrText] = useState("");

  const navigate = useNavigate();

  const {
    data: chatroom,
    isSuccess
  } = useGetChatroomByIdQuery(id, {skip: (id === undefined)});

  useEffect(() => {
    if (isSuccess) {
      setTitle(chatroom.title);
      setTeamId(chatroom.teamId);
      setThemeId(chatroom.themeId);
      if(chatroom.accounts) {
        setClientId(chatroom.accounts);
      }
      setStartDate(chatroom.startDate);
      setEndDate(chatroom.endDate);
    }
  }, [chatroom, isSuccess]);

  /* Team List 를 가져옵니다. */
  const {
    data: teamList,
    isSuccess: teamIsSuccess
  } = useGetTeamListAllQuery();

  /* Theme List 를 가져옵니다. */
  const {
    data: themeList,
    isSuccess: themeIsSuccess
  } = useGetThemeListAllQuery();

  /* Client List 를 가져옵니다. */
  const {
    data: clientList,
    isSuccess: clientIsSuccess
  } = useGetClientListAllQuery();

  /* Auth Join 요청 */
  const [create, {isLoading}] = useCreateChatroomMutation();

  const onChangeTitle = (e) => {
    setTitleErr(false);
    setTitle(e.target.value);
  };

  const onChangeTeamId = (e) => {
    setTeamIdErr(false);
    setTeamId(e.target.value);
  };

  const onChangeThemeId = (e) => {
    setThemeIdErr(false);
    setThemeId(e.target.value);
  };

  const onStartDateChange = (e) => {
    setStartDateErr(false);
    setStartDate(e.target.value);
  };

  const onEndDateChange = (e) => {
    setEndDateErr(false);
    setEndDate(e.target.value);
  };

  const fetchCreate = async () => {
    try {
      const chatroomData = {
        id,
        title,
        teamId,
        themeId,
        accounts: clientId,
        startDate,
        endDate
      };

      await create(chatroomData).unwrap();
      return navigate("/chatroom");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          errorInit(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onCreate = () => {
    if (!title) {
      errorInit("title", "채팅방 이름을 입력해 주세요.");
    }
    if (!teamId) {
      errorInit("teamId", "팀을 선택해 주세요.");
    }
    if (!themeId) {
      errorInit("themeId", "테마를 선택해 주세요.");
    }
    if (!startDate) {
      errorInit("startDate", "채팅방 시작날짜를 입력해 주세요.");
    }
    if (!endDate) {
      errorInit("endDate", "채팅방 종료날짜를 입력해 주세요.");
    }
    if (!title || !teamId || !themeId || !startDate || !endDate) {
      return false;
    }

    fetchCreate();
  };

  const onCheckEnter = (e) => {
    if (e.key === 'Enter') {
      onCreate();
    }
  }

  const errorInit = (type, message) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "title":
        setTitleErr(true);
        setTitleErrText(message);
        break;
      case "teamId":
        setTeamIdErr(true);
        setTeamIdErrText(message);
        break;
      case "themeId":
        setThemeIdErr(true);
        setThemeIdErrText(message);
        break;
      case "startDate":
        setStartDateErr(true);
        setStartDateErrText(message);
        break;
      case "endDate":
        setEndDateErr(true);
        setEndDateErrText(message);
        break;
    }
  }

  /* Team List Option */
  let teamOption;
  if (teamIsSuccess) {
    const {ids, entities} = teamList;
    teamOption = ids.map(id => (
        <MenuItem key={id}
                  value={entities[id].id}>{entities[id].name}</MenuItem>
    ));
  }

  /* Theme List Option */
  let themeOption;
  if (themeIsSuccess) {
    const {ids, entities} = themeList;
    themeOption = ids.map(id => (
        <MenuItem key={id}
                  value={entities[id].id}>{entities[id].title}</MenuItem>
    ));
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}
               onKeyPress={onCheckEnter}>
          <Typography
              sx={{mb: 4}}
              variant="h5"
              component="div"
              gutterBottom
          >
            채팅방 만들기
          </Typography>
          <TextField
              onChange={onChangeTitle}
              value={title}
              error={titleErr}
              helperText={titleErr ? titleErrText : ''}
              sx={{mb: 3}}
              label="* 채팅방 이름"
              id="filled-required"
              fullWidth/>
          <FormControl
              error={teamIdErr}
              sx={{mb: 3}}
              fullWidth>
            <InputLabel id="teamId-label">* 팀 선택</InputLabel>
            <Select
                onChange={onChangeTeamId}
                value={teamId ? teamId : ''}
                labelId="teamId-label"
                id="teamId"
                label="* 팀 선택">
              {teamOption}
            </Select>
            {teamIdErr ? <FormHelperText>{teamIdErrText}</FormHelperText> : ''}
          </FormControl>
          <FormControl
              sx={{mb: 3}}
              error={themeIdErr}
              fullWidth>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={9} md={11}>
                <InputLabel id="themeId-label">* 테마 선택</InputLabel>
                <Select
                    sx={{width: "100%"}}
                    onChange={onChangeThemeId}
                    value={themeId ? themeId : ''}
                    labelId="themeId-label"
                    id="themeId"
                    label="* 테마 선택"
                >
                  {themeOption}
                </Select>
              </Grid>
              <Grid item xs={12} sm={3} md={1}>
                {
                  themeId ?
                  <Tooltip title="테마 수정" placement="top" arrow>
                    <Link to={"/theme/create/" + themeId}>
                      <ThemeButton sx={{ pt: 1, pb: 1, width: "100%" }} variant="contained">
                        <AutoFixHighIcon/>
                      </ThemeButton>
                    </Link>
                  </Tooltip>
                  :
                  <Tooltip title="테마 만들기" placement="top" arrow>
                    <Link to={"/theme/create"}>
                      <ThemeButton sx={{ pt: 1, pb: 1, width: "100%" }} variant="contained">
                        <AddCircleIcon/>
                      </ThemeButton>
                    </Link>
                  </Tooltip>
                }
              </Grid>
            </Grid>
            {themeIdErr ? <FormHelperText>{themeIdErrText}</FormHelperText> : ''}
          </FormControl>

          {/* 클립 */}
          <FormControl
              error={startDateErr}
              sx={{mb: 3}}
              fullWidth>
            {
              clientIsSuccess ?
                  <ChipSelect
                      label="클라이언트 선택"
                      value={clientId}
                      onChange={setClientId}
                      options={clientList.clientList}
                      renderLabel={option => option.nickname}
                  />
                  :
                  null
            }
          </FormControl>

          <FormControl
              error={startDateErr}
              sx={{mb: 3}}
              fullWidth>
            <TextField
                onChange={onStartDateChange}
                value={startDate}
                error={startDateErr}
                helperText={startDateErr ? startDateErrText : ''}
                sx={{width: 250}}
                InputLabelProps={{
                  shrink: true,
                }}
                id="startData"
                label="* 시작 날짜"
                type="date"
            />
          </FormControl>
          <FormControl
              error={endDateErr}
              sx={{mb: 3}}
              fullWidth>
            <TextField
                onChange={onEndDateChange}
                value={endDate}
                error={endDateErr}
                helperText={endDateErr ? endDateErrText : ''}
                sx={{width: 250}}
                InputLabelProps={{
                  shrink: true,
                }}
                id="endData"
                label="* 종료 날짜"
                type="datetime-local"
            />
          </FormControl>
          <ThemeLoadingButton
              onClick={onCreate}
              sx={{mt: 3}}
              loading={isLoading}
              variant="contained"
          >SAVE</ThemeLoadingButton>
        </Paper>
      </FadeMotion>
  );
}

export default ChatroomCreate;