import React, {useEffect, useState} from 'react';
import {Box, Container, Alert, TextField} from "@mui/material";
import Logo from "../../assets/img/neodigm_logo.jpg";
import {
  FormWrapper,
  ImgBox,
  LinkButton
} from "./Auth.style";
import Button from "@mui/material/Button";
import {useNavigate, useSearchParams} from "react-router-dom";

import {motion, MotionConfig} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentToken, setCredentials} from '../../features/auth/authSlice';
import {useLoginMutation} from "../../features/auth/authApiSlice";
import {ThemeLoadingButton} from "../../components/layouts/Layout.style";
import {THEME} from "../../components/layouts/THEME";

function Login() {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const [errorText, setErrorText] = useState(null);
  const [errorErr, setErrorErr] = useState(null);

  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState(null);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(null);
  const [passwordErrText, setPasswordErrText] = useState("");
  const [authFailErr, setAuthFailErr] = useState(null);
  const [authFailErrText, setAuthFailErrText] = useState("");

  const navigate = useNavigate();

  const [login, {isLoading}] = useLoginMutation();
  const dispatch = useDispatch();

  /* Auth 상태체크 */
  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    if(token) {
      navigate("/chatroom");
    }

    if(error) {
      setErrorErr(true);
      setErrorText("로그인 유효시간이 만료되었습니다.");
    }
  }, [token, error, navigate]);

  const fetchLogin = async () => {
    try {
      const join = {
        username,
        password
      };

      const userData = await login(join).unwrap()
      dispatch(setCredentials({...userData, username}));

      setUsername('');
      setPassword('');
      navigate("/chatroom");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          errorInit(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onChangeUsername = (e) => {
    setUsernameErr(false);
    setAuthFailErr(false);
    setErrorErr(false);
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPasswordErr(false);
    setAuthFailErr(false);
    setErrorErr(false);
    setPassword(e.target.value);
  };

  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      onLogin();
    }
  }

  const onLogin = () => {
    if (!username) {
      errorInit("username", "아이디를 입력해 주세요.");
    }
    if (!password) {
      errorInit("password", "비밀번호를 입력해 주세요.");
    }
    if (!username || !password) {
      return false;
    }

    fetchLogin();
  };

  const errorInit = (type, message) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "username":
        setUsernameErr(true);
        setUsernameErrText(message);
        break;
      case "password":
        setPasswordErr(true);
        setPasswordErrText(message);
        break;
      case "authFail":
        setAuthFailErr(true);
        setAuthFailErrText(message);
        break;
    }
  }

  return (
      <div style={{overflow: "hidden"}}>
        <MotionConfig transition={{duration: 0.3}}>
          <motion.div
              initial={{opacity: 0, x: -50}}
              animate={{opacity: 1, x: 0}}
              exit={{x: -50, opacity: 0}}
          >
            <Container maxWidth="sm">
              <FormWrapper onKeyPress={onCheckEnter}>
                <Box>
                  <ImgBox>
                    <img src={Logo} alt="네오다임"/>
                  </ImgBox>
                  <TextField
                      onChange={onChangeUsername}
                      error={usernameErr}
                      helperText={usernameErr ? usernameErrText : ''}
                      sx={{mb: 2}}
                      label="아이디"
                      id="filled-required"
                      fullWidth/>
                  <TextField
                      onChange={onChangePassword}
                      error={passwordErr}
                      helperText={passwordErr ? passwordErrText : ''}
                      fullWidth
                      sx={{mb: 3}}
                      label="비밀번호"
                      id="filled-required"
                      type="password"/>
                  {errorErr ? <Alert severity="error" sx={{mb: 1}}>{errorText}</Alert> : ''}
                  {authFailErr ? <Alert severity="error" sx={{mb: 1}}>{authFailErrText}</Alert> : ''}
                  <ThemeLoadingButton
                      onClick={onLogin}
                      loading={isLoading}
                      variant="contained"
                      sx={{mb: 1}}
                      fullWidth
                      disableElevation>
                    로그인
                  </ThemeLoadingButton>
                  <LinkButton to="/join">
                    <Button
                        sx={{color: THEME.color, borderColor: THEME.color}}
                        variant="outlined"
                        fullWidth>
                      계정 만들기
                    </Button>
                  </LinkButton>
                </Box>
              </FormWrapper>
            </Container>
          </motion.div>
        </MotionConfig>
      </div>
  );
}

export default Login;