import React, {useCallback, useState} from 'react';
import {
  MessageInputWrapper,
  MessageTextArea,
  SendButton
} from "./ChatRoom.style";

function Publish({
  theme,
  onPublish
}) {
  const [message, setMessage] = useState("");

  const _onChange = useCallback(
      (e) => {
        setMessage(e.target.value);
      },
      []
  );

  const _onClick = () => {
    if(message) {
      onPublish(message);
      setMessage("");
    }
  }

  const _onKeyPress = (e) => {
    if(e.key === "Enter") {
      _onClick();
      e.preventDefault();
    }
  }

  return (
      <MessageInputWrapper sx={{display: 'flex'}}>
        <MessageTextArea
            placeholder={"메시지 작성 후 엔터키를 누르세요"}
            value={message}
            name={"message"}
            onChange={_onChange}
            onKeyPress={_onKeyPress}
        />
        <SendButton
            variant="contained"
            theme={{
              backgroundColor: theme.sendBtnBgColor,
              fontColor: theme.sendBtnColor
            }}
            onClick={_onClick}
        >
          전송
        </SendButton>
      </MessageInputWrapper>
  );
}

export default Publish;