import React, {useEffect, useState} from 'react'
import FadeMotion from "../../components/layouts/FadeMotion";
import {
  Paper,
  TextField,
  Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {
  useCreateTeamMutation,
  useGetTeamByIdQuery
} from "../../features/team/teamApiSlice";
import {ThemeLoadingButton} from "../../components/layouts/Layout.style";

function ChatroomCreate() {
  /* Update 시 id 값 존재함 */
  const {id} = useParams();

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(null);
  const [nameErrText, setNameErrText] = useState("");

  const navigate = useNavigate();

  const [create, {isLoading}] = useCreateTeamMutation();

  const {
    data: team,
    isSuccess
  } = useGetTeamByIdQuery(id, {skip: (id === undefined)});

  useEffect(() => {
    if(isSuccess) {
      setName(team.name);
    }
  }, [isSuccess, team]);

  const onChangeName = (e) => {
    setNameErr(false);
    setName(e.target.value);
  };

  const fetchCreate = async () => {
    try {
      const teamData = {
        id,
        name
      };

      await create(teamData).unwrap();
      return navigate("/team");
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          errorInit(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onCreate = () => {
    if (!name) {
      errorInit("name", "팀 이름을 입력해 주세요.");
    }
    if (!name) {
      return false;
    }

    fetchCreate();
  };

  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      onCreate();
    }
  }

  const errorInit = (type, message) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "name":
        setNameErr(true);
        setNameErrText(message);
        break;
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}} onKeyPress={onCheckEnter}>
          <Typography
              sx={{mb: 4}}
              variant="h5"
              component="div"
              gutterBottom
          >
            {id ? "팀 수정" : "팀 만들기"}
          </Typography>
          <TextField
              onChange={onChangeName}
              value={name}
              error={nameErr}
              helperText={nameErr ? nameErrText : ''}
              sx={{mb: 3}}
              label="* 팀 이름"
              id="filled-required"
              fullWidth/>
          <ThemeLoadingButton
              onClick={onCreate}
              sx={{mt: 3}}
              loading={isLoading}
              variant="contained"
          >SAVE</ThemeLoadingButton>
        </Paper>
      </FadeMotion>
  );
}

export default ChatroomCreate;