import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {Button, Tooltip} from "@mui/material";
import AddLinkIcon from '@mui/icons-material/AddLink';
import DvrIcon from '@mui/icons-material/Dvr';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useEffect} from "react";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import {LinkButton} from "../../containers/auth/Auth.style";
import {ToastCustom} from "../../utils/func";
import {ThemeButton} from "../layouts/Layout.style";

const columns = [
  { id: 'teamName', label: '팀', minWidth: 50 },
  { id: 'themeId', label: '테마', minWidth: 10 },
  {
    id: 'title',
    label: '제목',
    minWidth: 200,
  },
  {
    id: 'startDate',
    label: '시작 날짜',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'endDate',
    label: '종료 날짜',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'etc',
    label: '비고',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function ChatroomTable({
  chatroomList,
  totalItems,
  search,
  onChangeCurrentPage,
  onDestroy,
  isSuccess,
  state
}) {
  const domain = window.location.origin;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onChangeCurrentPage(newPage + 1);
  };

  /* 검색 시 페이징 초기화 */
  useEffect(() => {
    setPage(0);
  }, [search]);

  const _onDestroy = (id) => {
    Swal.fire({
      title: '채팅방과 채팅기록이 모두 사라집니다.',
      text: "정말로 삭제를 진행합니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        onDestroy(id)
        Swal.fire(
            '채팅방 삭제',
            '채팅방이 삭제되었습니다.',
            'success'
        )
      }
    });
  }

  const onCopy = (id) => {
    navigator.clipboard.writeText(domain + "/chatroom/enter/" + id + "?userId={id}&username={username}&company={company}");
    ToastCustom.fire({
      icon: 'success',
      title: '채팅창 주소를 복사하였습니다.'
    })
  }

  /* List Content */
  let content;
  if(isSuccess) {
    const { ids, entities } = chatroomList;
    content = ids.map(id => (
        <TableRow hover role="checkbox" tabIndex={-1} key={id}>
          {columns.map((column) => {
            switch (column.id) {
              case "themeId":
                return (
                    <TableCell key={column.id} align={column.align}>
                      <Tooltip title="테마 수정" placement="top" arrow>
                        <LinkButton to={"/theme/create/" + entities[id].themeId}>
                          <ThemeButton variant="contained">
                            <AutoFixHighIcon sx={{ mr: 1 }}/>
                            {entities[id].themeTitle}
                          </ThemeButton>
                        </LinkButton>
                      </Tooltip>
                    </TableCell>
                )
              case "etc":
                return (
                    <TableCell key={column.id} align={column.align}>
                      {
                        state ?
                            <>
                              <Tooltip title="채팅창 링크 복사" placement="top" arrow>
                                <ThemeButton sx={{ m: 1, pt: 1, pb: 1 }} onClick={() => onCopy(entities[id].id)} variant="contained">
                                  <AddLinkIcon/>
                                </ThemeButton>
                              </Tooltip>
                              <Tooltip title="채팅창 라이브 바로가기" placement="top" arrow>
                                <Link to={"/chatroom/enter/" + entities[id].id + "?userId=0&username=admin"} target={"_blank"}>
                                  <ThemeButton sx={{ m: 1, pt: 1, pb: 1 }} variant="contained">
                                    <ChatIcon/>
                                  </ThemeButton>
                                </Link>
                              </Tooltip>
                            </>
                        :
                        ""
                      }
                      <Tooltip title="채팅창 상세보기" placement="top" arrow>
                        <Link to={"/chatroom/"+ entities[id].id +"/view/"}>
                          <Button sx={{ m: 1, pt: 1, pb: 1 }} variant="contained" color="success">
                            <DvrIcon/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip title="채팅창 삭제" placement="top" arrow>
                        <Button
                            onClick={() => _onDestroy(entities[id].id)}
                            sx={{ m:1, pt: 1, pb: 1 }}
                            variant="contained"
                            color="error">
                          <DeleteForeverIcon/>
                        </Button>
                      </Tooltip>
                    </TableCell>
                )
              default:
                const value = entities[id][column.id];
                return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                    </TableCell>
                );
            }
          })}
        </TableRow>
    ));
  }

  return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
        />
      </Paper>
  );
}
