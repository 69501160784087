import React, {useEffect, useState} from 'react';
import {
  useDestroyChatroomMutation,
  useGetChatroomListQuery
} from "../../features/chatroom/chatroomApiSlice";
import {
  FlexContentBetween, SearchBox
} from "../../components/layouts/Layout.style";
import ChatroomTable from "../../components/tables/ChatroomTable";
import ChatroomTableClient from "../../components/tables/ChatroomTableClient";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {IconButton, Input, Paper, Tooltip} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {Link, useLocation} from "react-router-dom";
import FadeMotion from "../../components/layouts/FadeMotion";
import {THEME} from "../../components/layouts/THEME";
import {useSelector} from "react-redux";
import {selectCurrentRole} from "../../features/auth/authSlice";

function ChatroomList() {
  const location = useLocation();
  const state = (location.pathname !== "/chatroom/end");

  const role = useSelector(selectCurrentRole);
  const accessRole = ["ROLE_ADMIN", "ROLE_USER"];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [cate] = useState("title");
  const [search, setSearch] = useState("");

  const [destroy] = useDestroyChatroomMutation();
  const {
    data: chatroomList,
    isSuccess
  } = useGetChatroomListQuery({
     id: currentPage,
     patch: {
       cate: cate,
       search: search
    },
    state: state
  });

  useEffect(() => {
    if(isSuccess) {
      setTotalItems(chatroomList.totalItems);
    }
  }, [isSuccess, chatroomList]);

  const onChangeCurrentPage = (e) => {
    setCurrentPage(e);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    e.preventDefault();
  }

  const onDestroy = async (id) => {
    try {
      await destroy(id).unwrap();
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          console.log(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}>
          <FlexContentBetween>
            <SearchBox>
              <FlexContentBetween>
                <Input
                    onChange={onChangeSearch}
                    placeholder="제목으로 검색"
                    fullWidth/>
                <IconButton variant="contained">
                  <SearchIcon sx={{color: THEME.color}}/>
                </IconButton>
              </FlexContentBetween>
            </SearchBox>
            {
              accessRole.includes(role) ?
              <div>
                <Tooltip title="채팅방 만들기" placement="top" arrow>
                  <Link to={"/chatroom/create"}>
                    <IconButton variant="contained">
                      <AddCircleIcon sx={{color: THEME.color}}/>
                    </IconButton>
                  </Link>
                </Tooltip>
              </div>
              :
              null
            }
          </FlexContentBetween>
        </Paper>
        {
          accessRole.includes(role) ?
              <ChatroomTable
                  chatroomList={chatroomList}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  search={search}
                  onChangeCurrentPage={onChangeCurrentPage}
                  onDestroy={onDestroy}
                  isSuccess={isSuccess}
                  state={state}
              />
              :
              <ChatroomTableClient
                  chatroomList={chatroomList}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  search={search}
                  onChangeCurrentPage={onChangeCurrentPage}
                  isSuccess={isSuccess}
                  state={state}
              />
        }
      </FadeMotion>
  )
}

export default ChatroomList;