import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {LinkButton} from "./Layout.style";
import {THEME} from "./THEME";

export default function Breadcrumb({
  preTitle,
  preLink,
  title
}) {
  const breadcrumbs = [
    <LinkButton
        underline="hover"
        key="1"
        color="inherit"
        to={preLink}
    >
      {preTitle}
    </LinkButton>,
    <Typography key="2" sx={{fontWeight: "bold", color: THEME.color}}>
      {title}
    </Typography>,
  ];

  return (
      <Stack spacing={2} sx={{mb: 3}}>
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
            aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
  );
}
