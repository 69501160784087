import ChatroomList from "../containers/chatroom/ChatroomList";
import ProtectRoute from "./ProtectRoute";
import ChatroomCreate from "../containers/chatroom/ChatroomCreate";
import ChatroomView from "../containers/chatroom/ChatroomView";
import TeamList from "../containers/team/TeamList";
import TeamCreate from "../containers/team/TeamCreate";
import AuthList from "../containers/auth/AuthList";
import AuthCreate from "../containers/auth/AuthCreate";
import ThemeList from "../containers/theme/ThemeList";
import ThemeCreate from "../containers/theme/ThemeCreate";
import Login from "../containers/auth/Login";
import Join from "../containers/auth/Join";
import ChatRoomEnter from "../containers/chatsquare/ChatRoomEnter";
import IpList from "../containers/ip/IpList";

export const PATH =
{
  /* 일반 페이지 */
  authGuest: {
    path: {
      login: "/",
      join: "/join"
    },
    title: {
      login: "로그인",
      join: "회원가입"
    },
    element: {
      login: <Login/>,
      join: <Join/>
    }
  },
  chatroomGuest: {
    path: {
      enter: "/chatroom/enter/:roomId"
    },
    title: {
      enter: "채팅방 입장"
    },
    element: {
      enter: <ChatRoomEnter/>
    }
  },
  /* 관리자 페이지 */
  team: {
    path: {
      index: "/team",
      create: "/team/create",
      update: "/team/create/:id"
    },
    title: {
      index: "팀 관리",
      create: "팀 만들기",
      update: "팀 수정"
    },
    element: {
      index: <ProtectRoute roles={["ROLE_ADMIN"]} component={<TeamList/>}/>,
      store: <ProtectRoute roles={["ROLE_ADMIN"]} component={<TeamCreate/>}/>
    }
  },
  auth: {
    path: {
      index: "/auth",
      create: "/auth/create",
      update: "/auth/create/:id"
    },
    title: {
      index: "회원 관리",
      create: "회원 만들기",
      update: "회원 수정"
    },
    element: {
      index: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<AuthList/>}/>,
      store: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<AuthCreate/>}/>
    }
  },
  chatroom: {
    path: {
      index: "/chatroom",
      create: "/chatroom/create",
      update: "/chatroom/create/:id",
      view: "/chatroom/:id/view",
      indexEnd: "/chatroom/end"
    },
    title: {
      index: "채팅방 관리",
      create: "채팅방 만들기",
      update: "채팅방 수정",
      view: "채팅방 상세보기",
      indexEnd: "종료된 채팅방"
    },
    element: {
      index: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER", "ROLE_CLIENT"]} component={<ChatroomList/>}/>,
      store: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<ChatroomCreate/>}/>,
      view: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<ChatroomView/>}/>
    }
  },
  theme: {
    path: {
      index: "/theme",
      create: "/theme/create",
      update: "/theme/create/:id"
    },
    title: {
      index: "테마 관리",
      create: "테마 만들기",
      update: "테마 수정"
    },
    element: {
      index: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<ThemeList/>}/>,
      store: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER"]} component={<ThemeCreate/>}/>
    }
  },
  ip: {
    path: {
      index: "/ip"
    },
    title: {
      index: "차단된 IP 관리"
    },
    element: {
      index: <ProtectRoute roles={["ROLE_ADMIN", "ROLE_USER", "ROLE_CLIENT"]} component={<IpList/>}/>
    }
  }
};

/*
EX Object

team: {
  path: {
    index:,
    create:,
    update:,
    view:
        },
  title: {
    index:,
    create:,
    update:,
    view:
        },
  element: {
    index:,
    store:,
    view:
        }
},
*/
