import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import Error404 from "../components/errors/Error404"

import {AnimatePresence} from "framer-motion"
import PrivateRoute from "./PrivateRoute";
import AdminLayout from "../components/layouts/AdminLayout";
import {PATH} from "./PATH";

function AnimatedRoute() {

  const location = useLocation();

  return (
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route path={"*"} element={<Error404/>}/>
          <Route path={PATH.chatroomGuest.path.enter} element={PATH.chatroomGuest.element.enter}/>

          {/* 인증을 반드시 하지 않아야만 접속 가능한 페이지 정의 */}
          <Route element={<PrivateRoute authentication={false}/>}>
            <Route path={PATH.authGuest.path.login} element={PATH.authGuest.element.login}/>
            <Route path={PATH.authGuest.path.join} element={PATH.authGuest.element.join}/>
          </Route>

          {/* 인증을 반드시 해야지만 접속 가능한 페이지 정의 */}
          <Route element={<PrivateRoute authentication={true}/>}>
            {/* 권한 체크가 필요한 페이지 정의 */}
            {/* ProtectRoute 는 반드시 로그인한 사용자의 한해서만 되도록 구현되어 PrivateRoute 안에 종속되어야한다. */}
            <Route element={<AdminLayout />}>
              {/* Team */}
              <Route path={PATH.team.path.index} element={PATH.team.element.index}/>
              <Route path={PATH.team.path.create} element={PATH.team.element.store}/>
              <Route path={PATH.team.path.update} element={PATH.team.element.store}/>

              {/* Auth */}
              <Route path={PATH.auth.path.index} element={PATH.auth.element.index}/>
              <Route path={PATH.auth.path.create} element={PATH.auth.element.store}/>
              <Route path={PATH.auth.path.update} element={PATH.auth.element.store}/>

              {/* Chatroom */}
              <Route path={PATH.chatroom.path.index} element={PATH.chatroom.element.index}/>
              <Route path={PATH.chatroom.path.create} element={PATH.chatroom.element.store}/>
              <Route path={PATH.chatroom.path.update} element={PATH.chatroom.element.store}/>
              <Route path={PATH.chatroom.path.view} element={PATH.chatroom.element.view}/>
              <Route path={PATH.chatroom.path.indexEnd} element={PATH.chatroom.element.index}/>

              {/* Theme */}
              <Route path={PATH.theme.path.index} element={PATH.theme.element.index}/>
              <Route path={PATH.theme.path.create} element={PATH.theme.element.store}/>
              <Route path={PATH.theme.path.update} element={PATH.theme.element.store}/>

              {/* Ip */}
              <Route path={PATH.ip.path.index} element={PATH.ip.element.index}/>
            </Route>
          </Route>
        </Routes>
      </AnimatePresence>
  );
}

export default AnimatedRoute;
