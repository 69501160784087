import React from "react";
import { Chip, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

function ChipSelect({label, value, onChange, options, renderLabel}) {
  return (
      <TextField
          select
          value={value}
          fullWidth
          variant="outlined"
          label={label}
          multiline
          onChange={e => onChange(e.target.value)}
          SelectProps={{
            multiple: true,
            renderValue: selected =>
                selected.map(value => (
                    <Chip
                        key={`${renderLabel ? renderLabel(value) : value}-chip`}
                        label={renderLabel ? renderLabel(value) : value}
                        style={{marginRight: "4px"}}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => {
                          const newValue = [...selected];
                          const index = selected.findIndex(v => v === value);
                          newValue.splice(index, 1);
                          console.log({newValue});
                          onChange(newValue);
                        }}
                    />
                ))
          }}
      >
        {options.map(option => (
            <MenuItem
                key={`${renderLabel ? renderLabel(option) : option}-option`}
                value={option}
                disabled={Boolean(
                    value.find(o =>
                        renderLabel
                            ? renderLabel(o) === renderLabel(option)
                            : o === option
                    )
                )}
            >
              {renderLabel ? renderLabel(option) : option}
            </MenuItem>
        ))}
      </TextField>
  );
}

export default ChipSelect;