import {Navigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {
  selectCurrentRole,
  selectCurrentToken
} from "../features/auth/authSlice";

const ProtectRoute = (props) => {
  const {component, roles} = props;
  const isAuthenticated = useSelector(selectCurrentToken);
  const role = useSelector(selectCurrentRole);
  const userHasRequiredRole = roles.includes(role);

  if (isAuthenticated && userHasRequiredRole) {
    return component;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to='/'/>
  }

  return <Navigate to="/"/>
}

export default ProtectRoute;