import React, {useEffect, useState} from 'react';
import {
  FlexContentBetween, SearchBox
} from "../../components/layouts/Layout.style";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {IconButton, Input, Paper, Tooltip} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {Link} from "react-router-dom";
import FadeMotion from "../../components/layouts/FadeMotion";
import {
  useGetAuthListQuery,
  useDestroyAuthMutation
} from "../../features/auth/authApiSlice";
import AuthTable from "../../components/tables/AuthTable";
import {THEME} from "../../components/layouts/THEME";

function AuthList() {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [cate] = useState("nickname");
  const [search, setSearch] = useState("");

  const [destroy] = useDestroyAuthMutation();

  const {
    data: authList,
    isSuccess
  } = useGetAuthListQuery({
    "id": currentPage,
    "patch": {
      "cate": cate,
      "search": search
    }
  });

  useEffect(() => {
    if (isSuccess) {
      setTotalItems(authList.totalItems);
    }
  }, [authList, isSuccess]);

  const onChangeCurrentPage = (e) => {
    setCurrentPage(e);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    e.preventDefault();
  }

  const onDestroy = async (id) => {
    try {
      await destroy(id).unwrap();
    } catch (e) {
      if (e.originalStatus === 400) {
        return e;
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}>
          <FlexContentBetween>
            <SearchBox>
              <FlexContentBetween>
                <Input
                    onChange={onChangeSearch}
                    placeholder="회원 이름으로 검색"
                    fullWidth/>
                <IconButton variant="contained">
                  <SearchIcon sx={{color: THEME.color}}/>
                </IconButton>
              </FlexContentBetween>
            </SearchBox>
            <div>
              <Tooltip title="회원 만들기" placement="top" arrow>
                <Link to={"/auth/create"}>
                  <IconButton variant="contained">
                    <AddCircleIcon sx={{color: THEME.color}}/>
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          </FlexContentBetween>
        </Paper>
        <AuthTable
            authList={authList}
            totalItems={totalItems}
            currentPage={currentPage}
            search={search}
            onChangeCurrentPage={onChangeCurrentPage}
            onDestroy={onDestroy}
            isSuccess={isSuccess}
        />
      </FadeMotion>
  )
}

export default AuthList;