import {styled} from "@mui/system";

export const FlexBox = styled("div")`
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
`;

export const ColLg5 = styled("div")`
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
    width: 100%;

    @media (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
`;