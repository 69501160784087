import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Tooltip} from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {ThemeButton} from "../layouts/Layout.style";

const columns = [
  {
    id: 'title',
    label: '제목',
    minWidth: 200,
  },
  {
    id: 'startDate',
    label: '시작 날짜',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'endDate',
    label: '종료 날짜',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'etc',
    label: '비고',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function ChatroomTable({
  chatroomList,
  totalItems,
  search,
  onChangeCurrentPage,
  isSuccess,
  state
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onChangeCurrentPage(newPage + 1);
  };

  /* 검색 시 페이징 초기화 */
  useEffect(() => {
    setPage(0);
  }, [search]);

  /* List Content */
  let content;
  if(isSuccess) {
    const { ids, entities } = chatroomList;
    content = ids.map(id => (
        <TableRow hover role="checkbox" tabIndex={-1} key={id}>
          {columns.map((column) => {
            switch (column.id) {
              case "etc":
                return (
                    <TableCell key={column.id} align={column.align}>
                      {
                        state ?
                            <>
                              <Tooltip title="채팅창 라이브 바로가기" placement="top" arrow>
                                <Link to={"/chatroom/enter/" + entities[id].id + "?userId=0&username=admin"} target={"_blank"}>
                                  <ThemeButton sx={{ m: 1, pt: 1, pb: 1 }} variant="contained">
                                    <ChatIcon/>
                                  </ThemeButton>
                                </Link>
                              </Tooltip>
                            </>
                        :
                        ""
                      }
                    </TableCell>
                )
              default:
                const value = entities[id][column.id];
                return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                    </TableCell>
                );
            }
          })}
        </TableRow>
    ));
  }

  return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
        />
      </Paper>
  );
}
