import React from 'react'
import {
  NoticeWrapper,
  NoticeBox,
  NoticeEditButton,
  HtmlWrapper
} from "./ChatRoom.style"
import Swal from "sweetalert2";

function Notice({
  theme,
  notice,
  onNotice,
  onReplace,
  isAuthenticated
}) {

  const _onClick = () => {
    Swal.fire({
      title: '공지사항',
      input: 'textarea',
      inputValue: notice.message,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '저장',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        onNotice(result.value);
      }
    });
  }

  return (
      <NoticeWrapper>
        <NoticeBox
            theme={{
              backgroundColor: theme.noticeBgColor,
              fontColor: theme.noticeColor,
              fontSize: theme.noticeSize
            }}>
          {
            isAuthenticated ?
                <NoticeEditButton
                    size="small"
                    variant="contained"
                    onClick={_onClick}>
                  공지사항 수정
                </NoticeEditButton>
                :
                null
          }
          <HtmlWrapper dangerouslySetInnerHTML={ {__html: onReplace(notice.message)} }/>
        </NoticeBox>
      </NoticeWrapper>
  );
}

export default Notice;