import React, {useEffect, useState} from 'react';
import {
  useDestroyChattingMutation, useGetChatroomTitleByIdQuery,
  useGetChattingListQuery
} from "../../features/chatroom/chatroomApiSlice";
import {
  FlexContentBetween, LinkButton, SearchBox, ThemeButton
} from "../../components/layouts/Layout.style";
import {Box, IconButton, Input, Paper, Tooltip} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useParams} from "react-router-dom";
import FadeMotion from "../../components/layouts/FadeMotion";
import ChattingTable from "../../components/tables/ChattingTable";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Button from "@mui/material/Button";
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddLinkIcon from "@mui/icons-material/AddLink";
import {ToastCustom} from "../../utils/func";
import {SERVER_URL} from "../../routes";
import Link from '@mui/material/Link';
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../features/auth/authSlice";
import api from "../../app/api";
import Breadcrumb from "../../components/layouts/Breadcrumb";
import {PATH} from "../../routes/PATH";
import {THEME} from "../../components/layouts/THEME";

function ChatroomView() {
  const {id} = useParams();
  const domain = window.location.origin;

  const isAuthenticated = useSelector(selectCurrentToken);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [cate] = useState("username");
  const [search, setSearch] = useState("");

  const [destroy] = useDestroyChattingMutation();

  const {
    data: chattingList,
    isSuccess
  } = useGetChattingListQuery({
    id: currentPage,
    patch: {
      chatroomId: id,
      cate: cate,
      search: search
    }
  });

  /* 채팅방 제목 */
  const {
    data: chatroom,
    isSuccess : titleIsSuccess
  } = useGetChatroomTitleByIdQuery(id);

  useEffect(() => {
    if (isSuccess) {
      setTotalItems(chattingList.totalItems);
    }
  }, [isSuccess, chattingList]);

  const onChangeCurrentPage = (e) => {
    setCurrentPage(e);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    e.preventDefault();
  }

  const onDestroy = async (id) => {
    try {
      await destroy(id).unwrap();
    } catch (e) {
      if (e.status === 400) {
        if (e.data.field && e.data.defaultMessage) {
          console.log(e.data.field, e.data.defaultMessage);
        }
      } else {
        console.log(`Error : ${e}`);
      }
    }
  }

  const onCopy = (id) => {
    navigator.clipboard.writeText(domain + "/chatroom/enter/" + id
        + "?userId={id}&username={username}&company={company}");
    ToastCustom.fire({
      icon: 'success',
      title: '채팅창 주소를 복사하였습니다.'
    })
  }

  const onExcelDownload = async (type, title) => {
    try {
      const response = await api.get(
          SERVER_URL + "/api/excel/" + type + "/" + id,
          {
            headers: {
              "authorization": isAuthenticated
            },
            responseType: "blob"
          }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title + "_" + chatroom.title + response.headers['content-disposition']);
      document.body.appendChild(link);
      link.click();
    } catch(e) {
      console.log(e);
    }
  }

  return (
      <FadeMotion>
        <Paper elevation={1} sx={{mb: 2, pl: 2, pr: 2, pt: 2, pb: 2}}>
          <Breadcrumb
              preLink={PATH.chatroom.path.index}
              preTitle={PATH.chatroom.title.index}
              title={titleIsSuccess ? chatroom.title : "채팅방"}
          />
          <FlexContentBetween>
            <SearchBox>
              <FlexContentBetween>
                <Input
                    onChange={onChangeSearch}
                    placeholder="이름으로 검색"
                    fullWidth/>
                <IconButton variant="contained">
                  <SearchIcon sx={{color: THEME.color}}/>
                </IconButton>
              </FlexContentBetween>
            </SearchBox>
            <Box>
              <Tooltip title="채팅창 링크 복사" placement="top" arrow>
                <ThemeButton onClick={() => onCopy(id)} sx={{mr: 1}} variant="contained">
                  <AddLinkIcon/>
                </ThemeButton>
              </Tooltip>
              <LinkButton to={"/chatroom/create/" + id}>
                <Tooltip title="채팅방 수정" placement="top" arrow>
                  <ThemeButton variant="contained" sx={{mr: 1}}>
                    <AutoFixHighIcon/>
                  </ThemeButton>
                </Tooltip>
              </LinkButton>
              <Link onClick={() => onExcelDownload("chatting", "사용자 채팅 기록")}>
                <Tooltip title="채팅내역 엑셀 다운로드" placement="top" arrow>
                  <Button variant="contained" color={"success"} sx={{mr: 1}}>
                    <MessageIcon/>
                    <FileDownloadIcon/>
                  </Button>
                </Tooltip>
              </Link>
              <Link onClick={() => onExcelDownload("access", "접속한 사용자 목록")}>
                <Tooltip title="접속한 사용자 엑셀 다운로드" placement="top" arrow>
                  <Button variant="contained" color={"success"} sx={{mr: 1}}>
                    <GroupIcon/>
                    <FileDownloadIcon/>
                  </Button>
                </Tooltip>
              </Link>
              <Link onClick={() => onExcelDownload("log", "입장&퇴장 기록 사용자 목록")}>
                <Tooltip title="입장&퇴장 기록 엑셀 다운로드" placement="top" arrow>
                  <Button variant="contained" color={"success"}>
                    <SyncAltIcon/>
                    <FileDownloadIcon/>
                  </Button>
                </Tooltip>
              </Link>
            </Box>
          </FlexContentBetween>
        </Paper>
        <ChattingTable
            chattingList={chattingList}
            totalItems={totalItems}
            currentPage={currentPage}
            search={search}
            onChangeCurrentPage={onChangeCurrentPage}
            onDestroy={onDestroy}
            isSuccess={isSuccess}
        />
      </FadeMotion>
  )
}

export default ChatroomView;