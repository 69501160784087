import { apiSlice } from "../../app/api/apiSlice";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const ipAdapter = createEntityAdapter()

/* 기본 */
const initialState = ipAdapter.getInitialState();

export const ipApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getIpList: builder.query({
      query: (arg) => ({
        url:  '/api/ip/' + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = ipAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return ipAdapter.setAll(customState, responseData.ipList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Ip', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Ip', id }))
      ],
      keepUnusedDataFor: 60
    }),
    destroyIp: builder.mutation({
      query: (arg) => ({
        url: '/api/ip/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Ip', id: arg.id }
      ]
    })
  })
});

export const {
  useGetIpListQuery,
  useDestroyIpMutation
} = ipApiSlice;

// returns the query result object
export const selectIpResult = ipApiSlice.endpoints.getIpList.select();

// Creates memoized selector
const selectIpData = createSelector(
    selectIpResult,
    ipResult => ipResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllIp,
  selectById: selectIpById,
  selectIds: selectIpIds
  // Pass in a selector that returns the ip slice of state
} = ipAdapter.getSelectors(state => selectIpData(state) ?? initialState);