import React, {useEffect} from 'react';
import {
  InfoButton, ModalBox
} from "./ChatRoom.style";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText, Modal
} from "@mui/material";
import Typography from "@mui/material/Typography";

function ChattingInfo({
  userEnterList,
  onChatInfo,
  onDisConnect,
  userCount
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    onChatInfo();
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
  }, [userEnterList]);

  function _onClickDisConnect(username, company, userIp) {
    /* 접속자 리스트 팝업창 종료 */
    handleClose();

    Swal.fire({
      title: '강제 퇴장',
      text: `정말로 ${username + (company ? ' (' + company + ')'
          : '')} 님의 퇴장을 진행합니까?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '퇴장'
    }).then((result) => {
      if (result.isConfirmed) {
        onDisConnect(userIp);
        Swal.fire(
            '강제 퇴장',
            `${username + (company ? ' (' + company + ')'
                : '')} 님이 강제 퇴장되었습니다.`,
            'success'
        )
      }
    });
  }

  return (
      <Box>
        <InfoButton
            variant="contained"
            size="small"
            onClick={handleOpen}
        >
          접속자 수 {userCount}
        </InfoButton>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <ModalBox>
            <Box>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{"text-align": "center", mb: 1}}>채팅 접속자 {userCount} 명</Typography>
              {
                userEnterList.map(item => (
                    <ListItem
                        key={item.sessionId}
                        component="div"
                        disablePadding
                        secondaryAction={
                          <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => _onClickDisConnect(item.username, item.company, item.userIp)}>
                            <DeleteIcon/>
                          </IconButton>
                        }>
                      <ListItemButton>
                        <ListItemText
                            primary={`${item.username + (item.company ? ' (' + item.company + ')' : '')}`}
                        />
                      </ListItemButton>
                    </ListItem>
                ))
              }
            </Box>
          </ModalBox>
        </Modal>
      </Box>
  );
}

export default ChattingInfo;