import React from 'react'
import {motion, MotionConfig} from "framer-motion";
import {AdminContainer} from "./Layout.style";

function FadeMotion({children}) {
  return (
      <MotionConfig transition={{duration: 0.3}}>
        <motion.div
            initial={{opacity: 0, x: 20}}
            animate={{opacity: 1, x: 0}}
            exit={{x: 20, opacity: 0}}
        >
          <AdminContainer>
            {children}
          </AdminContainer>
        </motion.div>
      </MotionConfig>
  );
}

export default FadeMotion;