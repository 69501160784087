import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FormHelperText, TextField} from "@mui/material";

function JoinForm({
  teamId,
  teamIdErr,
  teamIdErrText,
  nickname,
  nicknameErr,
  nicknameErrText,
  username,
  usernameErr,
  usernameErrText,
  passwordErr,
  passwordErrText,
  onChangeTeamId,
  onChangeNickname,
  onChangeUsername,
  onChangePassword,
  teamList,
  teamIsSuccess
}) {

  /* Team List Option */
  let teamOption;
  if(teamIsSuccess) {
    const { ids, entities } = teamList;
    teamOption = ids.map(id => (
        <MenuItem key={id} value={entities[id].id}>{entities[id].name}</MenuItem>
    ));
  }

  return (
      <>
        <FormControl
            fullWidth
            sx={{mb: 2}}
            error={teamIdErr}>
          <InputLabel id="demo-simple-select-label">팀 선택</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={teamId}
              label="팀 선택"
              onChange={onChangeTeamId}>
            {teamOption}
          </Select>
          {teamIdErr ? <FormHelperText>{teamIdErrText}</FormHelperText> : ''}
        </FormControl>
        <TextField
            onChange={onChangeNickname}
            error={nicknameErr}
            helperText={nicknameErr ? nicknameErrText : ''}
            sx={{mb: 2}}
            value={nickname}
            label="이름"
            id="filled-required"
            fullWidth/>
        <TextField
            onChange={onChangeUsername}
            error={usernameErr}
            helperText={usernameErr ? usernameErrText : ''}
            value={username}
            label="아이디"
            id="filled-required"
            sx={{mb: 2}}
            fullWidth/>
        <TextField
            onChange={onChangePassword}
            error={passwordErr}
            helperText={passwordErr ? passwordErrText : ''}
            label="비밀번호"
            id="filled-required"
            type="password"
            sx={{mb: 3}}
            fullWidth/>
      </>
  );
}

export default JoinForm;