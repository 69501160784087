import { apiSlice } from "../../app/api/apiSlice";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const themeAdapter = createEntityAdapter()

/* 기본 */
const initialState = themeAdapter.getInitialState();

export const themeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getThemeListAll: builder.query({
      query: () => ({
        url:  "/api/theme/all"
      }),
      transformResponse: responseData => {
        return themeAdapter.setAll(initialState, responseData.themeList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Theme', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Theme', id }))
      ],
      keepUnusedDataFor: 60
    }),
    getThemeList: builder.query({
      query: (arg) => ({
        url:  '/api/theme/' + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = themeAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return themeAdapter.setAll(customState, responseData.themeList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Theme', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Theme', id }))
      ],
      keepUnusedDataFor: 60
    }),
    getThemeById: builder.query({
      query: (arg) => '/api/theme/create/' + arg,
      invalidatesTags: [
        { type: 'Theme', id: "LIST" }
      ],
      keepUnusedDataFor: 0
    }),
    createTheme: builder.mutation({
      query: arg => ({
        url: '/api/theme/create',
        method: 'POST',
        body: arg
      }),
      invalidatesTags: [
        { type: 'Theme', id: "LIST" }
      ],
    }),
    destroyTheme: builder.mutation({
      query: (arg) => ({
        url: '/api/theme/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Theme', id: arg.id }
      ]
    })
  })
});

export const {
  useGetThemeListAllQuery,
  useGetThemeListQuery,
  useGetThemeByIdQuery,
  useCreateThemeMutation,
  useDestroyThemeMutation
} = themeApiSlice;

// returns the query result object
export const selectThemeResult = themeApiSlice.endpoints.getThemeList.select();

// Creates memoized selector
const selectThemeData = createSelector(
    selectThemeResult,
    themeResult => themeResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTheme,
  selectById: selectThemeById,
  selectIds: selectThemeIds
  // Pass in a selector that returns the theme slice of state
} = themeAdapter.getSelectors(state => selectThemeData(state) ?? initialState);