import {styled} from '@mui/system'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

export const Container = styled("div")`
    background-color: #${(props => props.theme.backgroundColor)};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
`;

export const Wrapper = styled("div")`
    width: 100%;
    bottom: 36px;
    font-size: 14px;
    line-height: normal;
    top: 0;
    left: 0;
    position: absolute;
`;

export const MessageContainer = styled("div")`
    background-color: #${(props => props.theme.backgroundColor)};
    right: 0;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 90px;
    overflow: auto;
    overflow-y: scroll;
`;

export const NoticeWrapper = styled("div")`
    top: 0;
    z-index: 10;
    position: sticky!important;
`;

export const NoticeBox = styled("div")`
    background-color: #${(props => props.theme.backgroundColor)};
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    padding: 10px;
    border-top: none;
    z-index: 10;
    white-space: pre-wrap;
`;

export const NoticeEditButton = styled(Button)`
    color: #3b44ac;
    font-size: 14px;
    background-color: #ffffff!important;
    line-height: unset!important;
    border: 1px solid #3b44ac;
    font-weight: 600;
    font-size: small;
    margin-bottom: 2px;
`;

export const MessageWrapper = styled("ul")`
    padding-left: 0;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
`;

export const Message = styled("li")`
    text-align: ${(props => props.align)};
    position: relative;
    padding: 0 5px 0;
    color: #6c7982;
    line-height: 1.4em;
    list-style: none;
    cursor: pointer;
`;

export const MessageContent = styled("div")`
    position: relative;
    min-width: 0;
    min-height: 35px;
    margin: 12px 4px 8px 8px;
`;

export const MessageTimestamp = styled("div")`
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    padding-bottom: 5px;
    line-height: 1em;
`;

export const MessageProfile = styled("span")`
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    pointer-events: none;
    font-weight: bold;
`;

export const MessageLine = styled("span")`
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    padding: 0 5px;
`;

export const MessageText = styled("span")`
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    word-break: break-all;
    
    a {
      background-color: #fff;
      border-radius: 2px;
      padding: 3px 10px;
      text-decoration: none;
      color: #333333;
      display: inline-block;
    }
`;

export const Answer = styled("li")`
    color: #${(props => props.theme.fontColor)};
    font-size: ${(props => props.theme.fontSize)}px;
    background-color: #${(props => props.theme.backgroundColor)};
    border-bottom: 1px dashed #ddd;
    padding: 0.5rem;
    cursor: pointer;
`;

export const AnswerBox = styled("div")`
    display: flex;
`;

export const Question = styled("div")`
    padding-bottom: 0.5rem;
`;

export const MessageInputContainer = styled("div")`
    background-color: #${(props => props.theme.backgroundColor)};
    position: absolute;
    bottom: 10px;
    height: 80px;
    left: 0;
    right: 0;
`;

export const MessageInputWrapper = styled("div")`
    top: 2px;
    left: 0;
    right: 0;
    bottom: 2px;
    position: absolute;
`;

export const MessageTextArea = styled("textarea")`
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 4px;
    overflow: auto;
    overflow-x: hidden;
    font-size: 15px;
    resize: none;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    width: calc(100% - 80px);
    outline: none;
`;

export const SendButton = styled(Button)`
    background-color: #${(props => props.theme.backgroundColor)}!important;
    color: #${(props => props.theme.fontColor)};
    width: 80px;
    border-radius: unset;
    box-shadow: none;
`;

export const ClearButton = styled(Button)`
    position: absolute;
    bottom: 8px;
    left: 12px;
`;

export const InfoButton = styled(Button)`
    position: absolute;
    bottom: 8px;
    left: 100px;
`;

export const BusinessLink = styled(Link)`
    color: #${(props => props.theme.fontColor)};
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-weight: bold;
`;

export const HtmlWrapper = styled("div")` 
    a {
      background-color: #fff;
      border-radius: 2px;
      padding: 0px 10px;
      text-decoration: none;
      color: #333333;
      display: inline-block;
      word-break: break-all;
    }
`;

export const CenterBox = styled("div")` 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;  
`;

export const LogoImg = styled("img")` 
    width: 100%;
    max-width: 28px;
`;

export const MotionBase = styled("div")`
  animation: 0.7s ease-in-out loadEffect1;
  
  @keyframes loadEffect1 {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
  } 
`;

export const ModalBox = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 3px;  
  height: 450px;
  overflow-y: scroll;
`;

export const AnswerMessage = styled("div")`
    margin-left: 23px;
`;