import { apiSlice } from "../../app/api/apiSlice";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const teamAdapter = createEntityAdapter()

/* 기본 */
const initialState = teamAdapter.getInitialState();

export const teamApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTeamListAll: builder.query({
      query: () => ({
        url:  "/api/team/all"
      }),
      transformResponse: responseData => {
        return teamAdapter.setAll(initialState, responseData.teamList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Team', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Team', id }))
      ],
      keepUnusedDataFor: 60
    }),
    getTeamList: builder.query({
      query: (arg) => ({
        url:  '/api/team/' + arg.id,
        params: arg.patch
      }),
      transformResponse: responseData => {
        /* 커스텀 */
        const customState = teamAdapter.getInitialState({
          "totalItems": responseData.totalItems
        });

        return teamAdapter.setAll(customState, responseData.teamList);
      },
      providesTags: (result, error, arg) => [
        { type: 'Team', id: "LIST" },
        ...result.ids.map(id => ({ type: 'Team', id }))
      ],
      keepUnusedDataFor: 60
    }),
    getTeamById: builder.query({
      query: (arg) => '/api/team/create/' + arg,
      invalidatesTags: [
        { type: 'Team', id: "LIST" }
      ],
      keepUnusedDataFor: 60
    }),
    createTeam: builder.mutation({
      query: arg => ({
        url: '/api/team/create',
        method: 'POST',
        body: { ...arg }
      }),
      invalidatesTags: [
        { type: 'Team', id: "LIST" }
      ]
    }),
    destroyTeam: builder.mutation({
      query: (arg) => ({
        url: '/api/team/' + arg,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Team', id: arg.id }
      ]
    })
  })
});

export const {
  useGetTeamListAllQuery,
  useGetTeamListQuery,
  useGetTeamByIdQuery,
  useCreateTeamMutation,
  useDestroyTeamMutation
} = teamApiSlice;

// returns the query result object
export const selectTeamResult = teamApiSlice.endpoints.getTeamList.select();

// Creates memoized selector
const selectTeamData = createSelector(
    selectTeamResult,
    teamResult => teamResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTeam,
  selectById: selectTeamById,
  selectIds: selectTeamIds
  // Pass in a selector that returns the team slice of state
} = teamAdapter.getSelectors(state => selectTeamData(state) ?? initialState);